import React, { useEffect, useImperativeHandle } from "react";

import API, { graphqlOperation } from "@aws-amplify/api";

import { listArticlesByDate, getArticle } from "../../graphql/queries";
import {
  onCreateArticle,
  onUpdateArticle,
  onDeleteArticle,
} from "../../graphql/subscriptions";

export default React.forwardRef(ArticleProvider);
function ArticleProvider(props, ref) {
  const {
    user,
    articles,
    dispatchArticle,
    articlesNextToken,
    setArticlesNextToken,
    setIsLoading,
    putScrollSession,
    scrollToPrevious,
  } = props;

  const ARTICLE_CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
  const ARTICLE_UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
  const ARTICLE_DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
  const ARTICLE_INITIAL_QUERY = "INITIAL_QUERY";
  const ARTICLE_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const getArticles = async (type, nextToken = null) => {
    const res = await API.graphql(
      graphqlOperation(listArticlesByDate, {
        owner: user.username,
        sortDirection: "DESC",
        limit: 8,
        nextToken: nextToken,
      })
    );
    console.log("res", res);
    setArticlesNextToken(res.data.listArticlesByDate.nextToken);
    dispatchArticle({
      type: type,
      articles: res.data.listArticlesByDate.items,
    });
    setIsLoading(false);
  };

  const getAdditionalArticles = () => {
    // setIsLoading(true);
    console.log("getAdditionalArticles");
    console.log("articlesNextToken", articlesNextToken);

    if (articlesNextToken === null) return; //Reached the last page
    getArticles(ARTICLE_ADDITIONAL_QUERY, articlesNextToken);

    putScrollSession();
  };

  const fetchArticle = async (articleId) => {
    const res = await API.graphql(
      graphqlOperation(getArticle, {
        id: articleId,
      })
    )
      .then((result) => {
        console.log("result", result);
        console.log("getArticle then article", result.data.getArticle);
        dispatchArticle({
          type: ARTICLE_CREATE_SUBSCRIPTION,
          article: result.data.getArticle,
        });
        console.log("articlesNextToken", articlesNextToken);
      })
      .catch((err) => console.log("err", err))
      .finally((result) => {
        console.log("result", result);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    const variables = {
      owner: user.username,
    };

    if (!articles || !articles.length) {
      console.log("Do getArticles");
      getArticles(ARTICLE_INITIAL_QUERY);
    } else {
      console.log("Don't getArticles");
      // setIsLoading(false);
      console.log("useEffect articles", articles);
    }

    const createArticleSubscription = API.graphql(
      graphqlOperation(onCreateArticle, variables)
    ).subscribe({
      next: (msg) => {
        console.log("onCreateArticle subscription fired");
        console.log("msg", msg);
        console.log(
          "msg.value.data.onCreateArticle",
          msg.value.data.onCreateArticle
        );
        const article = msg.value.data.onCreateArticle;
        if (article.owner !== user.username) return;
        fetchArticle(article.id);
      },
      error: (err) => {
        console.log("err", err);
        createArticleSubscription.unsubscribe();
        console.log("sub error (none)");
      },
      close: () => console.log("sub done"),
    });

    const updateArticleSubscription = API.graphql(
      graphqlOperation(onUpdateArticle, variables)
    ).subscribe({
      next: (msg) => {
        console.log("onUpdateArticle subscription fired");
        console.log("msg", msg);
        console.log(
          "msg.value.data.onUpdateArticle",
          msg.value.data.onUpdateArticle
        );
        const article = msg.value.data.onUpdateArticle;
        if (article.owner !== user.username) return;
        dispatchArticle({
          type: ARTICLE_UPDATE_SUBSCRIPTION,
          article: article,
        });
      },
      error: (err) => {
        console.log("err", err);
        updateArticleSubscription.unsubscribe();
        console.log("sub error (none)");
      },
      close: () => console.log("sub done"),
    });

    const deleteArticleSubscription = API.graphql(
      graphqlOperation(onDeleteArticle, variables)
    ).subscribe({
      next: (msg) => {
        console.log("onDeleteArticle subscription fired");
        console.log("msg", msg);
        console.log(
          "msg.value.data.onDeleteArticle",
          msg.value.data.onDeleteArticle
        );
        const article = msg.value.data.onDeleteArticle;
        if (article.owner !== user.username) return;
        dispatchArticle({
          type: ARTICLE_DELETE_SUBSCRIPTION,
          article: article,
        });
      },
      error: (err) => {
        console.log("err", err);
        deleteArticleSubscription.unsubscribe();
        console.log("sub error (none)");
      },
      close: () => console.log("sub done"),
    });

    return () => {
      console.log("unmount Article");
      putScrollSession();
      createArticleSubscription.unsubscribe();
      updateArticleSubscription.unsubscribe();
      deleteArticleSubscription.unsubscribe();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    getAdditionalArticles,
  }));

  return <React.Fragment>{props.children}</React.Fragment>;
}
