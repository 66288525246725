import React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import CachedMediaDialog from "./CachedMediaDialog";
import CachedMedia from "./CachedMedia";

import CSSModules from "react-css-modules";
import style from "./MediaThumbnail.module.scss";

export default CSSModules(
  MediaThumbnail,
  { ...style },
  { allowMultiple: true }
);

function MediaThumbnail(props) {
  const { image, contentType, local } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    // <div>
    // </div>
    <React.Fragment>
      <IconButton onClick={handleClickOpen} className="media_thumbnail_buton" size="large">
        <Avatar variant="rounded" styleName="image-thumbnail-avator">
          <CachedMedia
            fileKey={image}
            thumbnail={true}
            contentType={contentType}
            local={local}
          />
        </Avatar>
      </IconButton>
      <CachedMediaDialog
        open={open}
        onClose={handleClose}
        image={image}
        contentType={contentType}
        local={local}
      />
    </React.Fragment>
  );
}
