import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";

import { useHistory } from "react-router-dom";

import CachedCardMedia from "./CachedCardMedia";

import CSSModules from "react-css-modules";
import style from "./ImageCardOpenArticle.module.scss";

export default CSSModules(
  ImageCardOpenArticle,
  { ...style },
  { allowMultiple: true }
);

function ImageCardOpenArticle(props) {
  const { fileKey, contentType, articleId } = props;

  const history = useHistory();

  return (
    <React.Fragment>
      <CssBaseline />
      <Card onClick={() => history.push("/article/" + articleId)}>
        <CardActionArea styleName="card-action-area">
          <CachedCardMedia
            fileKey={fileKey}
            contentType={contentType}
            thumbnail={true}
          />
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
}
