import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m4Theme = createTheme(adaptV4Theme({
  palette: {
    // apr　2021年のイースターは4月4日　イースターエッグ　緑黄色
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=C8E6C9&secondary.color=FFFF8D
    primary: {
      light: "#fbfffc",
      main: "#c8e6c9",
      dark: "#97b498",
      contrastText: "#000000",
    },
    secondary: {
      light: "#ffffbf",
      main: "#ffff8d",
      dark: "#cacc5d",
      contrastText: "#000000",
    },
  },
}));

export default m4Theme;
