import React, { forwardRef, useImperativeHandle } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Translate from "../../components/part/Translate";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = forwardRef((props, ref) => {
  const { message, title_id, handleExecute, input } = props;

  // console.log('input',input)

  const [open, setOpen] = React.useState(false);

  useImperativeHandle(ref, () => ({
    handleClickOpen() {
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleOK = () => {
    console.log("handleOk start");
    handleExecute(input);
    console.log("handleOk after handleExecute");
    handleClose();
    console.log("handleOk after handleClose");
  };

  return (
    <React.Fragment>
      <React.Fragment>{props.children}</React.Fragment>
      <Dialog
        className={title_id}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"><Translate id={title_id} /></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} variant="contained" className="cancel_button">
            <Translate id="cancel" />
          </Button>
          <Button
            onClick={() => handleOK()}
            variant="contained"
            color="secondary"
            className="ok_button"
          >
            <Translate id="ok" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default AlertDialogSlide;
