import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";

import { API, graphqlOperation } from "aws-amplify";
import { createLabel, updateLabel, deleteLabel } from "../../graphql/mutations";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AlertDialogSlide from "../../components/part/AlertDialogSlide";

import { useAuthDataContext } from "../../containers/AuthDataProvider";

import Translate from "../../components/part/Translate";
import { useIntl } from "react-intl";
const defaults = require("../../translations/en.json");

const LabelDialog = forwardRef((props, ref) => {
  const user = useAuthDataContext();
  const { title_id, label, mode } = props;

  const refDeleteLabelDialog = useRef();

  const { formatMessage } = useIntl();

  const [open, setOpen] = React.useState(false);

  useImperativeHandle(ref, () => ({
    handleClickOpen() {
      setOpen(true);
    },
    setFormState,
    formState,
  }));

  const handleClose = () => {
    setOpen(false);
  };

  // const initialState = { name: "", color: "#FFFFFF" };
  const initialState = {
    name: "",
    color: "#FFFFFF",
  };

  const [formState, setFormState] = useState(initialState);
  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  useEffect(() => {
    if (!!label) {
      // console.log("LabelDialog label",label)
    }
  });

  function submitLabel(e) {
    e.preventDefault();

    switch (mode) {
      case "update":
        console.log("updateLabel");
        try {
          if (!formState.name || !formState.color) return;

          console.log("formState", formState);
          const label = { ...formState };
          console.log("label", label);

          var postDate = new Date().toISOString();

          const variables = {
            input: {
              ...label,
              postedDate: postDate,
              owner: user.username,
            },
          };
          console.log("variables", variables);
          setFormState(initialState);

          const newLabel = API.graphql(graphqlOperation(updateLabel, variables))
            .then((resultLabel) => {
              console.log("resultLabel", resultLabel);
            })
            .catch((err) => console.log("err", err));
        } catch (err) {
          console.log("error updating label:", err);
        } finally {
          handleClose();
        }
        return;
        break;
      case "create":
        console.log("addLabel");
        try {
          if (!formState.name || !formState.color) return;

          const label = { ...formState };
          var postDate = new Date().toISOString();

          const variables = {
            input: {
              ...label,
              postedDate: postDate,
              owner: user.username,
            },
          };
          console.log("variables", variables);
          setFormState(initialState);

          const newLabel = API.graphql(graphqlOperation(createLabel, variables))
            .then((resultLabel) => {
              console.log("resultLabel", resultLabel);
            })
            .catch((err) => console.log("err", err));
        } catch (err) {
          console.log("error creating label:", err);
        } finally {
          handleClose();
        }
        return;
        break;
    }
  }

  const handleDeleteLabel = async () => {
    console.log("deleteLabel");

    try {
      if (!formState.name || !formState.color) return;

      const label = { ...formState };
      var postDate = new Date().toISOString();

      const deleteVariables = {
        input: { id: label.id },
      };
      console.log("deleteVariables", deleteVariables);
      setFormState(initialState);

      const deletedLabel = API.graphql(
        graphqlOperation(deleteLabel, deleteVariables)
      )
        .then((resultLabel) => {
          console.log("resultLabel", resultLabel);
        })
        .catch((err) => console.log("err", err));
    } catch (err) {
      console.log("error deleting label:", err);
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      styleName="dialog"
      className={title_id}
    >
      <DialogTitle id="form-dialog-title">
        <Translate id={title_id} />
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="label_name"
          label={formatMessage({
            id: "label_name",
            defaultMessage: defaults["label_name"],
          })}
          type="text"
          onChange={(event) => setInput("name", event.target.value)}
          value={formState.name}
          // placeholder="Name"
          fullWidth
        />
        <TextField
          autoFocus
          margin="dense"
          id="label_color"
          label={formatMessage({
            id: "label_color",
            defaultMessage: defaults["label_color"],
          })}
          type="color"
          onChange={(event) => setInput("color", event.target.value)}
          value={formState.color}
          // placeholder="Name"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" className="label_cancel">
          <Translate id="cancel" />
        </Button>
        <Button onClick={submitLabel} variant="contained" color="primary" className="label_ok">
          <Translate id="ok" />
        </Button>
        {!!label && (
          <React.Fragment>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                refDeleteLabelDialog.current.handleClickOpen();
              }}
              className="label_delete"
            >
              <Translate id="delete" />
            </Button>
            <AlertDialogSlide
              ref={refDeleteLabelDialog}
              message={formatMessage({
                id: "article.delete_confirm",
                defaultMessage: defaults["article.delete_confirm"],
              })}
              title_id="delete_label"
              handleExecute={() => handleDeleteLabel()}
            />
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default LabelDialog;
