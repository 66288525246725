import React, { useState, useEffect, useRef, useReducer } from "react";
import { useAuthDataContext } from "../../containers/AuthDataProvider";
import PersistentDrawerLeft from "../../components/part/PersistentDrawerLeft";
import CreateArticleFab from "../../components/part/CreateArticleFab";
import ArticleProvider from "../part/ArticleProvider";

import AttachmentList from "../../components/part/AttachmentList";

export default function MediaPage() {
  const user = useAuthDataContext();

  const [isLoading, setIsLoading] = useState(true);

  // ラベル・フィルターの追加
  const refPersistentDrawerLeft = useRef();
  const handleLabelFileter = (e) => {
    const { labelName, labelColor } = e.currentTarget.dataset;
    setFilterLabelName(labelName);
    setFilterLabelColor(labelColor);
  };

  const [filterLabelName, setFilterLabelName] = useState("");
  useEffect(() => {
    sessionStorage.setItem("filterLabelName", JSON.stringify(filterLabelName));
  }, [filterLabelName]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelName"));
    if (storageVar) {
      setFilterLabelName(storageVar);
    }
  }, []);

  const [filterLabelColor, setFilterLabelColor] = useState("");
  useEffect(() => {
    sessionStorage.setItem(
      "filterLabelColor",
      JSON.stringify(filterLabelColor)
    );
  }, [filterLabelColor]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelColor"));
    if (storageVar) {
      setFilterLabelColor(storageVar);
    }
  }, []);

  const ARTICLE_CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
  const ARTICLE_UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
  const ARTICLE_DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
  const ARTICLE_INITIAL_QUERY = "INITIAL_QUERY";
  const ARTICLE_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const articleReducer = (state, action) => {
    switch (action.type) {
      case ARTICLE_INITIAL_QUERY:
        return action.articles;
      case ARTICLE_ADDITIONAL_QUERY:
        return [...state, ...action.articles];
      case ARTICLE_CREATE_SUBSCRIPTION:
        return [action.article, ...state];
      case ARTICLE_UPDATE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1, action.article);
        console.log("renew state", state);
        return [...state];
      case ARTICLE_DELETE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1);
        console.log("renew state", state);
        return [...state];
      default:
        return state;
    }
  };

  const [articles, dispatchArticle] = useReducer(articleReducer, []);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articles"));
    if (storageVar) {
      dispatchArticle(storageVar);
    }
  }, []);

  const [articlesNextToken, setArticlesNextToken] = useState(null);
  useEffect(() => {
    sessionStorage.setItem(
      "articlesNextToken",
      JSON.stringify(articlesNextToken)
    );
  }, [articlesNextToken]);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articlesNextToken"));
    if (storageVar) {
      setArticlesNextToken(storageVar);
    }
  }, []);

  const refArticleAttachmentProvider = useRef();

  const [attachmentsOffset, setAttachmentsOffset] = useState(null);
  useEffect(() => {
    sessionStorage.setItem(
      "attachmentsOffset",
      JSON.stringify(attachmentsOffset)
    );
  }, [attachmentsOffset]);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("attachmentsOffset"));
    if (storageVar) {
      setAttachmentsOffset(storageVar);
    }
  }, []);

  const previewStyle = {
    width: 320,
    height: 320,
    objectFit: "cover",
    borderRadius: "50%",
  };

  function scrollToPrevious() {
    console.log("scrollToPrevious attachemnt");
    console.log("attachmentsOffset", attachmentsOffset);
    // if (!!attachmentsOffset) {
    window.scrollTo({
      top: attachmentsOffset,
      behavior: "smooth",
    });
    // }
  }

  function putScrollSession() {
    console.log("putScrollSession attachment");
    let _offset =
      window.pageYOffset ||
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    console.log("_offset", _offset);
    // setAttachmentsOffset(_offset);
    // なぜかこれだと、useEffectのreturn時(unmount時)にセットできないので、下の行のように直接sessionStorageにセットした
    sessionStorage.setItem("attachmentsOffset", _offset);
    console.log("attachmentsOffset", attachmentsOffset);
  }

  useEffect(() => {
    scrollToPrevious();
  }, [isLoading]);

  sessionStorage.setItem("prevPage", "/media");

  return (
    <React.Fragment>
      <ArticleProvider
        ref={refArticleAttachmentProvider}
        user={user}
        articles={articles}
        dispatchArticle={dispatchArticle}
        articlesNextToken={articlesNextToken}
        setArticlesNextToken={setArticlesNextToken}
        putScrollSession={putScrollSession}
        scrollToPrevious={scrollToPrevious}
        setIsLoading={setIsLoading}
      ></ArticleProvider>
      <PersistentDrawerLeft
        type="photo"
        handleLabelFileter={handleLabelFileter}
        filterLabelName={filterLabelName}
        filterLabelColor={filterLabelColor}
        ref={refPersistentDrawerLeft}
        setIsLoading={setIsLoading}
      >
        {!!refArticleAttachmentProvider.current && (
          <AttachmentList
            isLoading={isLoading}
            articles={articles}
            getAdditionalArticles={
              refArticleAttachmentProvider.current.getAdditionalArticles
            }
            articlesNextToken={articlesNextToken}
            filterLabelName={filterLabelName}
          />
        )}
        <CreateArticleFab />
      </PersistentDrawerLeft>
    </React.Fragment>
  );
}
