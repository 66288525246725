import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m2Theme = createTheme(adaptV4Theme({
  palette: {
    //　feb　豆まき　赤黒
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=D50000&secondary.color=212121
    primary: {
      light: "#ff5131",
      main: "#d50000",
      dark: "#9b0000",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#484848",
      main: "#212121",
      dark: "#000000",
      contrastText: "#ffffff",
    },
  },
}));

export default m2Theme;
