import { createTheme, adaptV4Theme } from "@mui/material/styles";

const solarizedTheme = createTheme({
  palette: {
    mode: "",
    primary: {
      // light: """,
      main: "#eee8d5",
      // dark: "",
      contrastText: "#586e75",
    },
    secondary: {
      // light: "",
      main: "#93a1a1",
      // dark: "",
      contrastText: "#fdf6e3",
    },
    divider: "#93a1a1",
    action: {
      active: "#2aa198",
      hover: "#268bd2",
      selected: "#cb4b16",
      disabled: "#6c71c4",
      //   disabledBackground:"",
    },
    background: {
      default: "#fdf6e3",
      paper: "#fdf6e3",
    },
    text: {
      primary: "#657b83",
      secondary: "#586e75",
      disabled: "#93a1a1",
    },
  },
});

export default solarizedTheme;

// https://ethanschoonover.com/solarized/
// $base03:    #002b36;
// $base02:    #073642;
// $base01:    #586e75; //light mode test.secondary, primary.contrastText
// $base00:    #657b83; //light mode text.primary
// $base0:     #839496;
// $base1:     #93a1a1; //light mode secondary main, divider, text.disabled
// $base2:     #eee8d5; //light mode primary main
// $base3:     #fdf6e3; //light mode background default, background paper, secondary.contrastText
// $yellow:    #b58900;
// $orange:    #cb4b16; // action.selected
// $red:       #dc322f;
// $magenta:   #d33682;
// $violet:    #6c71c4; // action.disabled
// $blue:      #268bd2; // action.hover
// $cyan:      #2aa198; // action.active
// $green:     #859900;
// @mixin rebase($rebase03,$rebase02,$rebase01,$rebase00,$rebase0,$rebase1,$rebase2,$rebase3)
// {
//     background-color:$rebase03;
//     color:$rebase0;
//     * { color:$rebase0; }
//     h1,h2,h3,h4,h5,h6 { color:$rebase1; border-color: $rebase0; }
//     a, a:active, a:visited { color: $rebase1; }
// }
// @mixin accentize($accent) {
//     a, a:active, a:visited, code.url { color: $accent; }
//     h1,h2,h3,h4,h5,h6 {color:$accent}
// }
// /* light is default mode, so pair with general html definition */
// html, .light { @include rebase($base3,$base2,$base1,$base0,$base00,$base01,$base02,$base03)}
// .dark  { @include rebase($base03,$base02,$base01,$base00,$base0,$base1,$base2,$base3)}
// html * {
//     color-profile: sRGB;
//     rendering-intent: auto;
// }
