import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m12Theme = createTheme(adaptV4Theme({
  palette: {
    // dec　クリスマス　緑赤
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=2E7D32&secondary.color=dd2c00/
    primary: {
      light: "#60ad5e",
      main: "#2e7d32",
      dark: "#005005",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ff6434",
      main: "#dd2c00",
      dark: "#a30000",
      contrastText: "#ffffff",
    },
  },
}));

export default m12Theme;
