import React, { useState, useContext, useEffect } from "react";

import AuthDataProvider from "./containers/AuthDataProvider";
import AuthRouter from "./containers/AuthRouter";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import ThemeContextProvider from "./theme/themeContext";
import { ThemeContext } from "./theme/themeContext";
import { Helmet } from "react-helmet";

import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from "@mui/material/CssBaseline";

import Amplify from "aws-amplify";
import awsExports from "./aws-exports";

import { useIntl } from "react-intl";
const defaults = require("./translations/en.json");

const { host } = window.location;

// Fix issues with multiple redirect urls.
// Try to figure out which one to use...
if (awsExports.oauth.redirectSignIn.includes(",")) {
  const filterHost = (url) => new URL(url).host === host;
  awsExports.oauth.redirectSignIn = awsExports.oauth.redirectSignIn
    .split(",")
    .filter(filterHost)
    .shift();
  awsExports.oauth.redirectSignOut = awsExports.oauth.redirectSignOut
    .split(",")
    .filter(filterHost)
    .shift();
}

console.log(
  "awsExports.oauth.redirectSignIn: " + awsExports.oauth.redirectSignIn
);
console.log(
  "awsExports.oauth.redirectSignOut: " + awsExports.oauth.redirectSignOut
);
Amplify.configure(awsExports);

function App() {
  const context = useContext(ThemeContext);
  useEffect(() => {
    const themeName = localStorage.getItem("theme");
    if (themeName !== null) {
      context.handleThemeChange(themeName);
    }
  }, [context]);

  const { formatMessage } = useIntl();

  //UserSetting.Provider
  const [switchState, setSwitchState] = React.useState(false);
  const changeSwitch = (event) => {
    setSwitchState(event.target.checked);
  };
  const value = {
    switchState,
    setSwitchState,
    changeSwitch,
  };

  // ブラウザの言語をJavascriptから調べる
  const language = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : navigator.language.split(/[-_]/)[0]; // language without region code

  useEffect(() => {
    if (language == "ar") {
      console.debug('document.body.setAttribute("dir", "rtl");');
      document.body.setAttribute("dir", "rtl");
    }
    if (language != "ar") {
      console.debug('document.body.setAttribute("dir", "");');
      document.body.setAttribute("dir", "");
    }
  }, [language]);

  return (
    <React.Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={context.theme}>
          <CssBaseline />
          <BrowserRouter>
            <UserSetting.Provider value={value}>
              <AuthDataProvider>
                <AuthRouter />
              </AuthDataProvider>
            </UserSetting.Provider>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  );
}

export default App;
export const UserSetting = React.createContext();
