import React from "react";
// import PropTypes from 'prop-types';
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { blue } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import CachedMedia from "./CachedMedia";
import StyledDialog from "./StyledDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
    close: {
      position: "fixed",
      top: "10px",
      right: "10px",
      backgroundColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.primary.contrastText}`,
      borderRadius: "50%",
    },
  })
);

export default function CachedMediaDialog(props) {
  const theme = useTheme();

  const classes = useStyles();
  const { onClose, open, image, contentType, local } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}
      fullScreen={true}
    >
      <CachedMedia fileKey={image} contentType={contentType} local={local} />
      <CancelIcon
        onClick={handleClose}
        className={classes.close}
        fontSize="large"
      />
    </StyledDialog>
  );
}

// ImageDialog.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
//   selectedValue: PropTypes.string.isRequired,
// };
