import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useRef,
  useReducer,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  AttributionControl,
  LayersControl,
} from "react-leaflet";
import axios from "axios";
import { useHistory } from "react-router-dom";
import MarkerClusterGroup from "react-leaflet-markercluster";

import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import RoomIcon from "@mui/icons-material/Room";

import { useIntl } from "react-intl";
const defaults = require("../../translations/en.json");

// require('~leaflet/dist/leaflet.css'); // inside .js file
require("react-leaflet-markercluster/dist/styles.min.css"); // inside .js file

// const center = [51.505, -0.09]
// const center = [35.3628018715203, 138.7307789619081]
const center = [36.104611, 140.084556];

const DisplayPosition = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    position,
    getMarkerPosition,
  }));

  const { map, refDraggableMarker, setCenterLat, setCenterLng, setZoom } =
    props;

  const [position, setPosition] = useState(map.getCenter());

  const getMarkerPosition = useCallback(() => {
    const marker = refDraggableMarker.current.markerRef.current;
    if (marker != null) {
      console.log("marker.getLatLng()", marker.getLatLng());
      refDraggableMarker.current.setMarkerPosition(marker.getLatLng());
    }

    let lng;
    lng = refDraggableMarker.current.markerPosition.lng % 360;
    if (lng > 180) {
      lng = lng - 360;
    } else if (lng < -180) {
      lng = lng * 360;
    }

    // let reverseGeoLocationURL = `https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=43.0686718333333&lon=141.351173694444`
    // let reverseGeoLocationURL = 'https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=' + refDraggableMarker.current.markerPosition.lat + '&lon=' + refDraggableMarker.current.markerPosition.lng
    // let reverseGeoLocationURL = 'https://nominatim.openstreetmap.org/reverse.php?lat=35.87695&lon=139.64798&zoom=18&format=jsonv2';
    let reverseGeoLocationURL =
      "https://nominatim.openstreetmap.org/reverse.php?lat=" +
      refDraggableMarker.current.markerPosition.lat +
      "&lon=" +
      lng +
      "&zoom=18&format=jsonv2";

    console.log("reverseGeoLocationURL", reverseGeoLocationURL);

    axios
      // .get(`https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=43.0686718333333&lon=141.351173694444`)             //リクエストを飛ばすpath
      .get(reverseGeoLocationURL) //リクエストを飛ばすpath
      .then((response) => {
        console.log("response", response);
        console.log("response.data.results", response.data);
        setApiResult(response.data.display_name);
      }) //成功した場合、postsを更新する（then）
      .catch(() => {
        console.log("通信に失敗しました");
      }); //失敗した場合(catch)
  }, []);

  const onMove = useCallback(
    (e) => {
      let c = map.getCenter();
      console.log("map.getCenter()", c);
      setCenterLat(c["lat"]);
      setCenterLng(c["lng"]);
      setZoom(map.getZoom());
      setPosition(c);
    },
    [map]
  );

  const onLocationFound = useCallback(
    (e) => {
      map.flyTo(e.latlng, map.getZoom());
    },
    [map]
  );

  const onLocationError = useCallback(
    (e) => {
      console.log("位置情報取得に失敗しました。");
      window.alert("位置情報取得に失敗しました。");
    },
    [map]
  );

  useEffect(() => {
    // jumpCurrentPosition();
    // refDisplayPosition.current.getMarkerPosition();
  }, []);

  const [apiResult, setApiResult] = useState("");

  useEffect(() => {
    map.on("move", onMove);
    map.on("locationfound", onLocationFound);
    map.on("locationerror", onLocationError);

    console.log("refDraggableMarker", refDraggableMarker);

    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove, apiResult]);

  return <React.Fragment></React.Fragment>;
});

const DraggableMarker = forwardRef((props, ref) => {
  const { article, user_id } = props;
  const history = useHistory();

  const iconMarkup = renderToStaticMarkup(
    // <RoomIcon fontSize='large' color='secondary'/>
    <RoomIcon style={{ fontSize: 50 }} color="secondary" />
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: "hogehoge", // CSSのクラス名。指定しないとデフォルトのleaflet-div-iconというクラスがつき、background: #fff;border: 1px solid #666;というスタイルがつきます
    iconSize: [50, 90], // iconSizeを指定しないと、マーカーの中心でなく、左上が指定した座標に来るようになります
  });

  return !article.latitude ? null : (
    <Marker
      icon={customMarkerIcon}
      draggable={true}
      eventHandlers={{
        click: (e) => {
          console.log("marker clicked", e);
          console.log("/article/" + article.id);
          history.push("/article/" + article.id);
        },
        dragstart: (e) => {
          console.log("marker dragstart", e);
        },
        dragend: (e) => {
          console.log("marker dragend", e);
        },
        drag: (e) => {
          console.log("marker drag", e);
        },
        mouseover: (e) => {
          console.log("marker mouseover", e);
        },
      }}
      position={[article.latitude, article.longitude]}
    >
      <Tooltip>{article.name}</Tooltip>
    </Marker>
  );
});

function SelectLocation(props) {
  const { articles, user_id, filterLabelName } = props;

  const refDisplayPosition = useRef();
  const refDraggableMarker = useRef();

  const [map, setMap] = useState(null);

  const [centerLat, setCenterLat] = useState(null);
  useEffect(() => {
    sessionStorage.setItem("centerLat", JSON.stringify(centerLat));
  }, [centerLat]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("centerLat"));
    if (storageVar) {
      setCenterLat(storageVar);
    }
  }, []);

  const [centerLng, setCenterLng] = useState(null);
  useEffect(() => {
    sessionStorage.setItem("centerLng", JSON.stringify(centerLng));
  }, [centerLng]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("centerLng"));
    if (storageVar) {
      setCenterLng(storageVar);
    }
  }, []);

  const [zoom, setZoom] = useState(null);
  useEffect(() => {
    sessionStorage.setItem("zoom", JSON.stringify(zoom));
  }, [zoom]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("zoom"));
    if (storageVar) {
      setZoom(storageVar);
    }
  }, []);

  // const [initialPosition, setInitialPosition] = useState([centerLat, centerLng])
  const [initialPosition, setInitialPosition] = useState(center);

  let maxLat, maxLng, minLat, minLng;

  React.useEffect(() => {
    Object.keys(articles).map((key) => {
      console.log("articles[key].latitude):" + articles[key].latitude);
      console.log("articles[key].longitude):" + articles[key].longitude);

      if (
        articles[key].latitude != null &&
        (!maxLat || maxLat < articles[key].latitude)
      ) {
        maxLat = articles[key].latitude;
      }
      if (
        articles[key].latitude != null &&
        (!minLat || minLat > articles[key].latitude)
      ) {
        minLat = articles[key].latitude;
      }
      if (
        articles[key].longitude != null &&
        (!maxLng || maxLng < articles[key].longitude)
      ) {
        maxLng = articles[key].longitude;
      }
      if (
        articles[key].longitude != null &&
        (!minLng || minLng > articles[key].longitude)
      ) {
        minLng = articles[key].longitude;
      }
    });
    var centerLat = (maxLat + minLat) / 2;
    var centerLng = (maxLng + minLng) / 2;
    console.log(
      "maxLat:" +
        maxLat +
        ", minLat:" +
        minLat +
        ", maxLng:" +
        maxLng +
        ", minLng:" +
        minLng
    );
    console.log("(maxLat + minLat)/2: " + centerLat);
    console.log("(maxLng + minLng)/2: " + centerLng);

    if (!!map && !!maxLat && !!maxLng && !!minLat && !!minLng) {
      console.log("flyToBounds");
      map.flyToBounds(
        [
          [maxLat, maxLng],
          [minLat, minLng],
        ],
        { duration: 0.25 }
      );
    }
  }, [map, articles]);

  React.useEffect(() => {
    if (!!map && !!centerLat && !!centerLng && !!zoom) {
      console.log("fly ");
      map.flyTo([centerLat, centerLng], zoom, { duration: 0.25 });
    }
    // vh初期化
    setFillHeight();
  }, [map]);

  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  // 画面のサイズ変動があった時に高さを再計算する
  window.addEventListener("resize", setFillHeight);

  const { formatMessage } = useIntl();

  const displayMap = useMemo(
    () => (
      <MapContainer
        center={initialPosition}
        zoom={zoom}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{ height: "calc( ( var(--vh, 1vh) * 100 ) - 56px - 56px)" }}
      >
        <AttributionControl position="bottomleft" prefix={false} />
        <LayersControl position="bottomleft">
          <LayersControl.BaseLayer
            checked
            name={formatMessage({
              id: "map",
              defaultMessage: defaults["map"],
            })}
          >
            <TileLayer
              attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
              url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            name={formatMessage({
              id: "map_picture",
              defaultMessage: defaults["map_picture"],
            })}
          >
            <TileLayer
              attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
              url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            name={formatMessage({
              id: "picture",
              defaultMessage: defaults["picture"],
            })}
          >
            <TileLayer
              attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
              url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
          <MarkerClusterGroup>
            {articles.map(
              (article) =>
                !!article &&
                (!filterLabelName ||
                  article.labels.items.find((item) => {
                    return (
                      !!item &&
                      !!item.label &&
                      item.label.name == filterLabelName
                    );
                  })) && (
                  <DraggableMarker
                    map={map}
                    article={article}
                    user_id={user_id}
                  />
                )
            )}
          </MarkerClusterGroup>
        </LayersControl>
      </MapContainer>
    ),
    [articles, filterLabelName]
  );

  return (
    <div>
      {map ? (
        <DisplayPosition
          ref={refDisplayPosition}
          map={map}
          refDraggableMarker={refDraggableMarker}
          setCenterLat={setCenterLat}
          setCenterLng={setCenterLng}
          setZoom={setZoom}
        />
      ) : null}
      {/* {!maxLat || !maxLng || !minLat || !minLng ? (
        <div>位置情報が存在しません</div>
      ) : ( */}
      {displayMap}
      {/* )} */}
    </div>
  );
}

export default SelectLocation;
