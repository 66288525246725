import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import { useAuthDataContext } from "../../containers/AuthDataProvider";
import PersistentDrawerLeft from "../../components/part/PersistentDrawerLeft";
import CreateArticleFab from "../../components/part/CreateArticleFab";

import { Button } from "@mui/material";
import ArticleProvider from "../part/ArticleProvider";

import Translate from "../../components/part/Translate";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import allLocales from "@fullcalendar/core/locales-all";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";

import "./Calendar.css";
import CSSModules from "react-css-modules";
import style from "./Calendar.module.scss";
export default CSSModules(Calendar, { ...style }, { allowMultiple: true });

const thisMonth = () => {
  const today = new Date();
  return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
    2,
    "0"
  )}`;
};

function Calendar(props) {
  const user = useAuthDataContext();

  const [isProgress, setIsProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);

  // ラベル・フィルターの追加
  const refPersistentDrawerLeft = useRef();
  const handleLabelFileter = (e) => {
    const { labelName, labelColor } = e.currentTarget.dataset;
    setFilterLabelName(labelName);
    setFilterLabelColor(labelColor);
  };

  const [filterLabelName, setFilterLabelName] = useState("");
  useEffect(() => {
    sessionStorage.setItem("filterLabelName", JSON.stringify(filterLabelName));
  }, [filterLabelName]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelName"));
    if (storageVar) {
      setFilterLabelName(storageVar);
    }
  }, []);

  const [filterLabelColor, setFilterLabelColor] = useState("");
  useEffect(() => {
    sessionStorage.setItem(
      "filterLabelColor",
      JSON.stringify(filterLabelColor)
    );
  }, [filterLabelColor]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelColor"));
    if (storageVar) {
      setFilterLabelColor(storageVar);
    }
  }, []);

  const ARTICLE_CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
  const ARTICLE_UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
  const ARTICLE_DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
  const ARTICLE_INITIAL_QUERY = "INITIAL_QUERY";
  const ARTICLE_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const articleReducer = (state, action) => {
    switch (action.type) {
      case ARTICLE_INITIAL_QUERY:
        setIsLoading(false);
        return action.articles;
      case ARTICLE_ADDITIONAL_QUERY:
        setIsLoading(false);
        return [...state, ...action.articles];
      case ARTICLE_CREATE_SUBSCRIPTION:
        return [action.article, ...state];
      case ARTICLE_UPDATE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1, action.article);
        console.log("renew state", state);
        return [...state];
      case ARTICLE_DELETE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1);
        console.log("renew state", state);
        return [...state];
      default:
        return state;
    }
  };

  const [articles, dispatchArticle] = useReducer(articleReducer, []);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articles"));
    if (storageVar) {
      dispatchArticle(storageVar);
    }
  }, []);

  const [articlesNextToken, setArticlesNextToken] = useState(null);
  useEffect(() => {
    sessionStorage.setItem(
      "articlesNextToken",
      JSON.stringify(articlesNextToken)
    );
  }, [articlesNextToken]);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articlesNextToken"));
    if (storageVar) {
      setArticlesNextToken(storageVar);
    }
  }, []);

  const refArticleAttachmentProvider = useRef();

  function putScrollSession() {}
  function scrollToPrevious() {}

  //////////

  // useEffectが実行されているかどうかを判定するために用意しています
  const isFirstRef = useRef(true);

  sessionStorage.setItem("prevPage", "/calendar");

  const handleDateClick = useCallback((arg) => {
    console.debug("handleDateClick:", arg.dateStr);
    // alert(arg.dateStr);
  }, []);

  const history = useHistory();

  const handleEventClick = useCallback((info) => {
    // console.log("handleEventClick");
    // alert("Event: " + info.event.title);
    history.push("/article/" + info.event.id);
    // change the border color just for fun
    info.el.style.borderColor = "red";
  }, []);

  // カレンダーの日付範囲が、何らかの方法で設定または変更され、DOMが更新された後に呼び出される
  const handleDatesSet = useCallback((arg) => {
    if (!isProgress) {
      setIsProgress(true);
      // カレンダーの週初めが前月になってしまうので、一週間後を取得し、calendarDateとして保存
      // console.debug("arg.start", arg.start);
      let startDateMS = Date.parse(arg.start);
      let startDate = new Date(startDateMS);
      // console.log("startDate:", startDate);
      let calendarStartDateMS = startDate.setDate(startDate.getDate() + 7); //一週間後
      let calendarStartDate = new Date(calendarStartDateMS);
      console.debug("calendarStartDate", calendarStartDate);
      setCalendarDate(calendarStartDate);
    }
  }, []);

  const intl = useIntl();

  const calendarRef = React.createRef();

  //カレンダー日付
  const [calendarDate, setCalendarDate] = useState(Date().toISOString);
  useEffect(() => {
    sessionStorage.setItem("calendarDate", JSON.stringify(calendarDate));
  }, [calendarDate]);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("calendarDate"));
    if (storageVar) {
      setCalendarDate(storageVar);
    }
  }, []);

  useEffect(() => {
    // 取得したarticlesをカレンダー表示用に詰め替え
    console.log("filterLabelName:", filterLabelName);
    let maped_events = articles.map((article) => {
      let label_color = "";
      let label_name = "";
      let article_color = "";
      //   console.log("article:", article);
      if (
        !!article.labels.items[0] &&
        !!article.labels.items[0].label &&
        "color" in article.labels.items[0].label
      ) {
        label_color = article.labels.items[0].label.color;
        label_name = article.labels.items[0].label.name;
      }

      return {
        title: article.name,
        date: article.postedDate,
        id: article.id,
        color: label_color,
        textColor: label_color,
        label_name: label_name,
        allDay: true,
      };
    });

    // フィルター指定があれば、カレンダー表示用データをフィルタリング
    if (!!filterLabelName) {
      maped_events = maped_events.filter((event) => {
        return event.label_name == filterLabelName;
      });
    }

    //カレンダー表示用データを画面反映
    setEvents(maped_events);
    // console.log("events:",events);

    console.log("calendarDate:", calendarDate);
    //カレンダー日付が定義済みならば
    if (
      calendarDate &&
      refArticleAttachmentProvider.current &&
      !!articles[0] &&
      "postedDate" in articles[0]
    ) {
      let oldestPostedDateISOString = articles[articles.length - 1].postedDate;
      let oldestPostedDateElapsedMS = Date.parse(oldestPostedDateISOString);
      let oldestPostedDate = new Date(oldestPostedDateElapsedMS);
      console.log(
        "oldestPostedDate.toISOString():",
        oldestPostedDate.toISOString()
      );

      if (calendarDate < oldestPostedDate && !!articlesNextToken) {
        console.log("articlesの追加読み込み必要");
        refArticleAttachmentProvider.current.getAdditionalArticles();
      } else {
        console.log("articlesの追加読み込み不要");
        setIsProgress(false);
      }
    } else {
      setIsProgress(false);
    }
    //   }, [articles, filterLabelName]);
  }, [articles, filterLabelName, calendarDate]);

  return (
    <React.Fragment>
      <ArticleProvider
        ref={refArticleAttachmentProvider} // カレンダーAPI利用の準備
        user={user}
        articles={articles}
        dispatchArticle={dispatchArticle}
        articlesNextToken={articlesNextToken}
        setArticlesNextToken={setArticlesNextToken}
        putScrollSession={putScrollSession}
        scrollToPrevious={scrollToPrevious}
        setIsLoading={setIsLoading}
      ></ArticleProvider>
      <PersistentDrawerLeft
        type="calendar"
        handleLabelFileter={handleLabelFileter}
        filterLabelName={filterLabelName}
        filterLabelColor={filterLabelColor}
        ref={refPersistentDrawerLeft}
        setIsLoading={setIsLoading}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locales={allLocales}
          locale={intl.locale}
          height="auto"
          events={events}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          datesSet={handleDatesSet}
          initialDate={calendarDate} //初期表示の日にち
        />
        <CreateArticleFab />
        <Dialog open={isProgress}>
          <Backdrop open={true}>
            <Grid container alignItems="center" justifyContent="center">
              <CircularProgress />
            </Grid>
          </Backdrop>
        </Dialog>
      </PersistentDrawerLeft>
    </React.Fragment>
  );
}
