import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m11Theme = createTheme(adaptV4Theme({
  palette: {
    // nov　紅葉　ウォームカラーの落葉。濃い茶色濃い黄色
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=FFCA28&primary.color=795548
    primary: {
      light: "#a98274",
      main: "#795548",
      dark: "#4b2c20",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#fffd61",
      main: "#ffca28",
      dark: "#c79a00",
      contrastText: "#000000",
    },
  },
}));

export default m11Theme;
