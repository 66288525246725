import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m5Theme = createTheme(adaptV4Theme({
  palette: {
    // may　子供の日　紺色、赤
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=01579B&secondary.color=dd2c00
    primary: {
      light: "#4f83cc",
      main: "#01579b",
      dark: "#002f6c",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ff6434",
      main: "#dd2c00",
      dark: "#a30000",
      contrastText: "#ffffff",
    },
  },
}));

export default m5Theme;
