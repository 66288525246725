import React, { useState, forwardRef } from "react";
import { MapContainer, TileLayer, Marker, LayersControl } from "react-leaflet";

import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Slide from "@mui/material/Slide";
import Link from "@mui/material/Link";

import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import RoomIcon from "@mui/icons-material/Room";

import Translate from "../../components/part/Translate";

const zoom = 18;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  locationInput: {
    width: "calc(92vw - 48px)",
  },
  coordinates: {
    marginLeft: "10px",
  },
}));

const IconPosition = forwardRef((props, ref) => {
  const classes = useStyles();

  const { latitude, longitude, positionName } = props;

  return (
    <React.Fragment>
      <span>{!!positionName ? positionName : ""}</span>
    </React.Fragment>
  );
});

function ViewLocation(props) {
  const classes = useStyles();
  const { latitude, longitude, positionName } = props;
  // const initialPosition = [latitude, longitude];

  const [map, setMap] = useState(null);

  const iconMarkup = renderToStaticMarkup(
    // <RoomIcon fontSize='large' color='secondary'/>
    <RoomIcon style={{ fontSize: 50 }} color="secondary" />
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: "hogehoge", // CSSのクラス名。指定しないとデフォルトのleaflet-div-iconというクラスがつき、background: #fff;border: 1px solid #666;というスタイルがつきます
    iconSize: [50, 90], // iconSizeを指定しないと、マーカーの中心でなく、左上が指定した座標に来るようになります
  });

  const displayMap = (
    <React.Fragment>
      <MapContainer
        center={[latitude, longitude]}
        zoom={zoom}
        scrollWheelZoom={false}
        whenCreated={setMap}
        style={{ height: "calc(100vh - 56px - 56px)" }}
      >
        <LayersControl position="bottomleft">
          <LayersControl.BaseLayer checked name="地図">
            <TileLayer
              attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
              url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="地図＋写真">
            <TileLayer
              attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
              url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="写真">
            <TileLayer
              attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
              url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            />
          </LayersControl.BaseLayer>
          <Marker
            icon={customMarkerIcon}
            position={[latitude, longitude]}
            eventHandlers={{
              click: (e) => {
                // console.log("marker clicked", e);
                window.location.href =
                  "https://www.google.com/maps/search/?api=1&query=" +
                  latitude +
                  "%2C" +
                  longitude;
              },
            }}
          ></Marker>
        </LayersControl>
      </MapContainer>
      <div>
        {positionName}
        <br />
        <a
          href={
            "https://www.google.com/maps/search/?api=1&query=" +
            latitude +
            "%2C" +
            longitude
          }
          //   color="inherit"
          //   // color="textPrimary"
          //   // color="primary"
        >
          <Translate id="open_in_google_maps" />
        </a>
      </div>
    </React.Fragment>
  );

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id="view_location_panel">
      <IconButton
        styleName="edit-icon"
        aria-label="edit"
        onClick={handleClickOpen}
        id="view_location"
        size="large">
        <LocationOnIcon />
      </IconButton>
      <a
        onClick={handleClickOpen}
        // color="inherit"
        // // color="textPrimary"
        // // color="primary"
      >
        <IconPosition
          latitude={latitude}
          longitude={longitude}
          positionName={positionName}
        />
      </a>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <Translate id="show_position" />
            </Typography>
          </Toolbar>
        </AppBar>
        {displayMap}
      </Dialog>
    </div>
  );
}

export default ViewLocation;
