import React, { useEffect } from "react";

import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";

import { Storage } from "aws-amplify";

import CSSModules from "react-css-modules";
import style from "./CachedCardMedia.module.scss";

export default CSSModules(
  CachedCardMedia,
  { ...style },
  { allowMultiple: true }
);

function CachedCardMedia(props) {
  const MAXAGE = 604800;
  const { fileKey, contentType, thumbnail: thumbnailFlag } = props;
  const [url, setUrl] = React.useState("");

  //チェック対象のする拡張子
  let allow_exts = new Array("jpg", "jpeg", "png");

  //アップロード予定のファイル名の拡張子を確認する関数
  function checkExt(filename) {
    //比較のため小文字にする
    let ext = getExt(filename).toLowerCase();
    //許可する拡張子の一覧(allow_exts)から対象の拡張子があるか確認する
    if (allow_exts.indexOf(ext) === -1) return false;
    return true;
  }

  //ファイル名から拡張子を取得する関数
  function getExt(filename) {
    let pos = filename.lastIndexOf(".");
    if (pos === -1) return "";
    return filename.slice(pos + 1);
  }

  // 拡張子を確認し、サムネイルであるprefixに変換する
  const getThumbnailFileKey = (fileKey) => {
    if (checkExt(fileKey)) {
      let pos = fileKey.lastIndexOf("-o.");
      if (pos !== -1) {
        let fileExt = fileKey.slice(pos + "-o.".length);
        let fileBody = fileKey.slice(0, pos);
        let thumbailFileKey = fileBody + "-t." + fileExt;
        // console.log("thumbailFileKeyあり: ", thumbailFileKey);
        return thumbailFileKey;
      }
    }
    // console.log("thumbailFileKeyなし: ", fileKey);
    return fileKey;
  };

  useEffect(() => {
    if (thumbnailFlag) {
      // 拡張子を確認し、サムネイルであるprefixに変換する
      // console.log("thumbailFlag ON");

      let thumbailFileKey = getThumbnailFileKey(fileKey);

      //thumbnailの場合、サムネイル画像のkeyを計算し、Storage.getする
      //Storage.get失敗したら、元画像をStorage.getする
      let thumnailfileKeyExitstOnLocalStorage;
      if (
        (thumnailfileKeyExitstOnLocalStorage =
          sessionStorage.getItem("attachmentUrl" + thumbailFileKey) ||
          sessionStorage.getItem("attachmentUrl" + fileKey))
      ) {
        if (!!thumnailfileKeyExitstOnLocalStorage) {
          setUrl(sessionStorage.getItem("attachmentUrl" + thumbailFileKey));
          // console.log(
          //   "sessionStorage.getItem('attachmentUrl'" + thumbailFileKey + "))",
          //   sessionStorage.getItem("attachmentUrl" + thumbailFileKey)
          // );
        } else {
          setUrl(sessionStorage.getItem("attachmentUrl" + fileKey));
          // console.log(
          //   "sessionStorage.getItem('attachmentUrl'" + fileKey + "))",
          //   sessionStorage.getItem("attachmentUrl" + fileKey)
          // );
        }
      } else {
        Storage.get(thumbailFileKey, {
          expires: MAXAGE,
          level: "private",
          cacheControl: "max-age=" + MAXAGE,
        })
          .then((re) => {
            // console.log("Storage.get " + thumbailFileKey + ":then", re);
            setUrl(re);
            sessionStorage.setItem("attachmentUrl" + thumbailFileKey, re);
          })
          .catch((err) => {
            console.log("catchを書いても動いてなさそう。", err);
          });
      }
    } else {
      //thumbnailではない場合、元画像をStorage.getする
      // console.log("thumbailFlag OFF");

      if (sessionStorage.getItem("attachmentUrl" + fileKey)) {
        setUrl(sessionStorage.getItem("attachmentUrl" + fileKey));
        // console.log(
        //   "sessionStorage.getItem('attachmentUrl'" + fileKey + "))",
        //   sessionStorage.getItem("attachmentUrl" + fileKey)
        // );
      } else {
        Storage.get(fileKey, {
          expires: MAXAGE,
          level: "private",
          cacheControl: "max-age=" + MAXAGE,
        })
          .then((re) => {
            // console.log("Storage.get url", re);
            setUrl(re);
            sessionStorage.setItem("attachmentUrl" + fileKey, re);
          })
          .catch((err) => {
            // console.log("catchを書いても動いてなさそう。", err);
          });
      }
    }
  }, [fileKey]);

  const onError = () => {
    console.log("(onError)");

    // if (thumbnailFlag) {
    //   // 拡張子を確認し、サムネイルであるprefixに変換する
    //   console.log("onErrorにおける thumbnailFlag ON");

    //   let thumbailFileKey = getThumbnailFileKey(fileKey);

    //   Storage.get(thumbailFileKey, {
    //     expires: MAXAGE,
    //     level: "private",
    //     cacheControl: "max-age=" + MAXAGE,
    //   })
    //     .then((re) => {
    //       console.log("(onError)Storage.get thumbnail then url", re);
    //       setUrl(re);
    //       sessionStorage.setItem("attachmentUrl" + thumbailFileKey, re);
    //     })
    //     .catch((err) => {
    //       console.log("(onError)catchを書いても動いてなさそう。",err);
    //     });
    // } else {
    console.log("onErrorにおける thumbnailFlag OFF");

    Storage.get(fileKey, {
      expires: MAXAGE,
      level: "private",
      cacheControl: "max-age=" + MAXAGE,
    })
      .then((re) => {
        console.log("Storage.get url", re);
        setUrl(re);
        sessionStorage.setItem("attachmentUrl" + fileKey, re);
      })
      .catch((err) => {
        console.log("(onError)catchを書いても動いてなさそう。", err);
      });
    // }
  };

  return (
    <React.Fragment>
      {(() => {
        if (/^image\/\S+$/.test(contentType)) {
          if (!thumbnailFlag) {
            return (
              <CardMedia
                component="img"
                alt="image"
                height="250"
                image={url}
                title="image"
                styleName="card-media"
                key={fileKey}
                onError={onError}
              />
            );
          } else {
            return (
              <CardMedia
                component="img"
                alt="image"
                height="164"
                image={url}
                title="image"
                styleName="card-media"
                key={fileKey}
                onError={onError}
              />
            );
          }
        } else if (/^video\/\S+$/.test(contentType)) {
          if (!thumbnailFlag) {
            return (
              <CardMedia
                component="video"
                alt="video"
                height="250"
                image={url}
                title="video"
                styleName="card-media"
                key={fileKey}
                onError={onError}
              />
            );
          } else {
            return (
              <CardMedia
                component="video"
                alt="video"
                height="164"
                image={url}
                title="video"
                styleName="card-media"
                key={fileKey}
                onError={onError}
              />
            );
          }
        } else {
          return <CircularProgress color="inherit" />;
        }
      })()}
    </React.Fragment>
  );
}
