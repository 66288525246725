import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m1Theme = createTheme(
  adaptV4Theme({
    palette: {
      // jan　お正月　白赤
      // https://m2.material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFC107&secondary.color=D50000
      primary: {
        // light: "#fff350",
        main: "#ffc107",
        // dark: "#c79100",
        // contrastText: "#000000",
      },
      secondary: {
        // light: "#ff5131",
        main: "#d50000",
        // dark: "#9b0000",
        // contrastText: "#ffffff",
      },
      contrastThreshold: 4.5,
      tonalOffset: 0.2,
    },
  })
);

export default m1Theme;
