import React, { useState, useReducer } from "react";

import API, { graphqlOperation } from "@aws-amplify/api";

import { Button, TextField } from "@mui/material";

import ArticleList from "../../components/part/ArticleList";
import CreateArticleFab from "../../components/part/CreateArticleFab";
import PersistentDrawerLeft from "../../components/part/PersistentDrawerLeft";

// import { searchArticles } from '../graphql/queries'

export default function ArticleSearch(props) {
  const ARTICLE_SUBSCRIPTION = "SUBSCRIPTION";
  const ARTICLE_INITIAL_QUERY = "INITIAL_QUERY";
  const ARTICLE_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const articleReducer = (state, action) => {
    switch (action.type) {
      case ARTICLE_INITIAL_QUERY:
        return action.articles;
      case ARTICLE_ADDITIONAL_QUERY:
        return [...state, ...action.articles];
      case ARTICLE_SUBSCRIPTION:
        return [action.post, ...state];
      default:
        return state;
    }
  };

  const [articles, dispatchArticle] = useReducer(articleReducer, []);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");

  const clickSearchArticles = async (type, nextToken = null) => {
    console.log("searchArticles called: ", query);
    if (query === "") return;
    const res = null;
    // const res = await API.graphql(graphqlOperation(searchArticles, {
    //     // filter: { description: { matchPhrase: query } },
    //     filter: { description: { wildcard: "*"+query+"*" } },
    //     limit: 20,
    //     nextToken: nextToken,
    // }));
    console.log("res", res);
    dispatchArticle({ type: type, articles: res.data.searchArticles.items });
    setNextToken(res.data.searchArticles.nextToken);
    setIsLoading(false);
  };

  const getAdditionalArticles = () => {
    if (nextToken === null) return; //Reached the last page
    clickSearchArticles(ARTICLE_ADDITIONAL_QUERY, nextToken);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <React.Fragment>
      <PersistentDrawerLeft>
        {/* <Header/> */}
        <ArticleList
          isLoading={isLoading}
          articles={articles}
          getAdditionalArticles={getAdditionalArticles}
          listHeaderTitle={"Search"}
          listHeaderTitleButton={
            <React.Fragment>
              <TextField
                label="Enter Keywords"
                multiline
                maxRows="3"
                variant="filled"
                value={query}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  clickSearchArticles(ARTICLE_INITIAL_QUERY);
                }}
                fullWidth
              >
                Search
              </Button>
            </React.Fragment>
          }
        />
        <CreateArticleFab />
      </PersistentDrawerLeft>
    </React.Fragment>
  );
}
