import React, { useState } from "react";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import MediaThumbnail from "../part/MediaThumbnail";

import Translate from "../../components/part/Translate";

import CSSModules from "react-css-modules";
import style from "./InputImageWithPreview.module.scss";

var createObjectURL =
  (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;

export default CSSModules(
  InputImageWithPreview,
  { ...style },
  { allowMultiple: true }
);

function InputImageWithPreview(props) {
  var fileRef = props.fileRef;

  const [fileSrcs, setImageSrcs] = useState([]);

  const handleChangeFile = (event) => {
    //filename
    console.log("value", event.target.value);
    //file
    console.log("files", event.target.files);

    const dt = new DataTransfer();

    // ①イベントからfileの配列を受け取る
    var files = fileRef.current.files;

    var fileUrls = [];
    for (var i = 0; i < files.length; i++) {
      // ②createObjectURLで、files[0]を読み込む
      fileUrls[i] = files[i];
      // fileUrls[i] = createObjectURL(files[i]);
      dt.items.add(files[i]);
    }
    fileRef.current.files = dt.files;
    console.log("fileRef.current.files", fileRef.current.files);

    console.log("fileUrls", fileUrls);
    // ③setStateする！
    setImageSrcs(fileUrls);
  };

  var imgstyle = {
    width: "150px",
  };

  return (
    <React.Fragment>
      <div>
        <Button component="label" variant="contained">
          <Translate id="select_file" />
          <input
            type="file"
            accept="image/*, video/*"
            ref={fileRef}
            onChange={handleChangeFile}
            multiple
            hidden
          />
        </Button>
      </div>
      <div>
        {fileSrcs.map((fileSrc, fileIndex) => (
          <div key={createObjectURL(fileSrc)} styleName="image-thumbnail">
            <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    console.log("fileIndex:" + fileIndex);

                    const dt = new DataTransfer();
                    var files = fileRef.current.files;
                    var fileUrls = [];
                    for (var i = 0; i < files.length; i++) {
                      if (i != fileIndex) {
                        dt.items.add(files[i]);
                        fileUrls.push(files[i]);
                      }
                    }
                    fileRef.current.files = dt.files;
                    console.log("fileRef.current.files", fileRef.current.files);

                    console.log("fileUrls", fileUrls);
                    setImageSrcs(fileUrls);

                    // var tmpfiles = fileSrcs.filter(function (item, index) {
                    //   return index != fileIndex;
                    // });
                    // console.log("tmpfiles:", tmpfiles);
                    // setImageSrcs(tmpfiles);
                  }}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              }
            >
              <MediaThumbnail
                image={createObjectURL(fileSrc)}
                contentType={fileSrc.type}
                local={true}
              />

              {/* <IconButton>
              <Avatar variant="rounded">
                {/^image\/\S+$/.test(fileSrc.type) && (
                  <img src={createObjectURL(fileSrc)} style={imgstyle} />
                )}
                {/^video\/\S+$/.test(fileSrc.type) && (
                  <video
                    src={createObjectURL(fileSrc)}
                    autoPlay
                    loop
                    controls
                    style={imgstyle}
                  />
                )}
              </Avatar>
            </IconButton> */}
            </Badge>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}
