/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createArticle = /* GraphQL */ `
  mutation CreateArticle(
    $input: CreateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    createArticle(input: $input, condition: $condition) {
      id
      name
      description
      owner
      postedDate
      attachments {
        items {
          id
          contentType
          path
          owner
          articleId
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      positionName
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle(
    $input: UpdateArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    updateArticle(input: $input, condition: $condition) {
      id
      name
      description
      owner
      postedDate
      attachments {
        items {
          id
          contentType
          path
          owner
          articleId
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      positionName
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle(
    $input: DeleteArticleInput!
    $condition: ModelArticleConditionInput
  ) {
    deleteArticle(input: $input, condition: $condition) {
      id
      name
      description
      owner
      postedDate
      attachments {
        items {
          id
          contentType
          path
          owner
          articleId
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      positionName
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      contentType
      path
      owner
      articleId
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      contentType
      path
      owner
      articleId
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      contentType
      path
      owner
      articleId
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLabel = /* GraphQL */ `
  mutation CreateLabel(
    $input: CreateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    createLabel(input: $input, condition: $condition) {
      id
      name
      color
      owner
      postedDate
      articles {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLabel = /* GraphQL */ `
  mutation UpdateLabel(
    $input: UpdateLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    updateLabel(input: $input, condition: $condition) {
      id
      name
      color
      owner
      postedDate
      articles {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLabel = /* GraphQL */ `
  mutation DeleteLabel(
    $input: DeleteLabelInput!
    $condition: ModelLabelConditionInput
  ) {
    deleteLabel(input: $input, condition: $condition) {
      id
      name
      color
      owner
      postedDate
      articles {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createArticleLabelJoin = /* GraphQL */ `
  mutation CreateArticleLabelJoin(
    $input: CreateArticleLabelJoinInput!
    $condition: ModelArticleLabelJoinConditionInput
  ) {
    createArticleLabelJoin(input: $input, condition: $condition) {
      id
      articleId
      labelId
      owner
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      label {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateArticleLabelJoin = /* GraphQL */ `
  mutation UpdateArticleLabelJoin(
    $input: UpdateArticleLabelJoinInput!
    $condition: ModelArticleLabelJoinConditionInput
  ) {
    updateArticleLabelJoin(input: $input, condition: $condition) {
      id
      articleId
      labelId
      owner
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      label {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteArticleLabelJoin = /* GraphQL */ `
  mutation DeleteArticleLabelJoin(
    $input: DeleteArticleLabelJoinInput!
    $condition: ModelArticleLabelJoinConditionInput
  ) {
    deleteArticleLabelJoin(input: $input, condition: $condition) {
      id
      articleId
      labelId
      owner
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      label {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
