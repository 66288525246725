import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m7Theme = createTheme(adaptV4Theme({
  // jly　初夏　緑
  // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=66BB6A&secondary.color=00796B
  palette: {
    primary: {
      light: "#98ee99",
      main: "#66bb6a",
      dark: "#338a3e",
      contrastText: "#000000",
    },
    secondary: {
      light: "#48a999",
      main: "#00796b",
      dark: "#004c40",
      contrastText: "#ffffff",
    },
  },
}));

export default m7Theme;
