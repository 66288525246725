/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateArticle = /* GraphQL */ `
  subscription OnCreateArticle($owner: String!) {
    onCreateArticle(owner: $owner) {
      id
      name
      description
      owner
      postedDate
      attachments {
        items {
          id
          contentType
          path
          owner
          articleId
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      positionName
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateArticle = /* GraphQL */ `
  subscription OnUpdateArticle($owner: String!) {
    onUpdateArticle(owner: $owner) {
      id
      name
      description
      owner
      postedDate
      attachments {
        items {
          id
          contentType
          path
          owner
          articleId
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      positionName
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteArticle = /* GraphQL */ `
  subscription OnDeleteArticle($owner: String!) {
    onDeleteArticle(owner: $owner) {
      id
      name
      description
      owner
      postedDate
      attachments {
        items {
          id
          contentType
          path
          owner
          articleId
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      positionName
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment($owner: String!) {
    onCreateAttachment(owner: $owner) {
      id
      contentType
      path
      owner
      articleId
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment($owner: String!) {
    onUpdateAttachment(owner: $owner) {
      id
      contentType
      path
      owner
      articleId
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment($owner: String!) {
    onDeleteAttachment(owner: $owner) {
      id
      contentType
      path
      owner
      articleId
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLabel = /* GraphQL */ `
  subscription OnCreateLabel($owner: String!) {
    onCreateLabel(owner: $owner) {
      id
      name
      color
      owner
      postedDate
      articles {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLabel = /* GraphQL */ `
  subscription OnUpdateLabel($owner: String!) {
    onUpdateLabel(owner: $owner) {
      id
      name
      color
      owner
      postedDate
      articles {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLabel = /* GraphQL */ `
  subscription OnDeleteLabel($owner: String!) {
    onDeleteLabel(owner: $owner) {
      id
      name
      color
      owner
      postedDate
      articles {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateArticleLabelJoin = /* GraphQL */ `
  subscription OnCreateArticleLabelJoin($owner: String!) {
    onCreateArticleLabelJoin(owner: $owner) {
      id
      articleId
      labelId
      owner
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      label {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateArticleLabelJoin = /* GraphQL */ `
  subscription OnUpdateArticleLabelJoin($owner: String!) {
    onUpdateArticleLabelJoin(owner: $owner) {
      id
      articleId
      labelId
      owner
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      label {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteArticleLabelJoin = /* GraphQL */ `
  subscription OnDeleteArticleLabelJoin($owner: String!) {
    onDeleteArticleLabelJoin(owner: $owner) {
      id
      articleId
      labelId
      owner
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      label {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
