import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m9Theme = createTheme(adaptV4Theme({
  // sep　月見9/21　黒黄色
  // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=263238&secondary.color=FFF176
  palette: {
    primary: {
      light: "#4f5b62",
      main: "#263238",
      dark: "#000a12",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ffffa8",
      main: "#fff176",
      dark: "#cabf45",
      contrastText: "#000000",
    },
  },
}));

export default m9Theme;
