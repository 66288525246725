import React, { useState, useEffect, useRef } from "react";

import { API, graphqlOperation } from "aws-amplify";

import { listLabels } from "../../graphql/queries";
import {
  onCreateLabel,
  onUpdateLabel,
  onDeleteLabel,
  onCreateArticleLabelJoin,
  onDeleteArticleLabelJoin,
} from "../../graphql/subscriptions";

import { Link } from "react-router-dom";

import Chip from "@mui/material/Chip";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";

import LabelDialog from "../../components/part/LabelDialog";

import CSSModules from "react-css-modules";
import style from "./LabelEdit.module.scss";

import Translate from "../../components/part/Translate";
import { useIntl } from "react-intl";
const defaults = require("../../translations/en.json");

function LabelEdit(props) {
  const {
    user,
    labels,
    setLabels,
    articleLabelItems,
    setArticleLabelItems,
    fetchArticle,
  } = props;
  const { formatMessage } = useIntl();

  const refCreateDialog = useRef();
  const [labelLength, setLabelLength] = useState(3);
  const refsEditLabelDialog = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const [labeLoading, setLabelLoading] = useState(true);

  const getLabels = async () => {
    const res = await API.graphql(
      graphqlOperation(listLabels, {
        owner: user.username,
        // limit: 5,
        // nextToken: nextToken,
      })
    );
    console.log("res", res);
    console.log("res.data.listLabels.items", res.data.listLabels.items);

    setLabels(res.data.listLabels.items);
    setLabelLoading(false);
  };

  function updateLabelLength(value) {
    // console.log("updateLabelLength",value)
    setLabelLength(value);
    refsEditLabelDialog.current = refsEditLabelDialog.current.splice(0, value);
    for (let i = 0; i < value; i++) {
      refsEditLabelDialog.current[i] =
        refsEditLabelDialog.current[i] || React.createRef();
    }
  }

  const handleAddArticleLabelJoins = (labelId) => {
    console.log("addArticleLabelJoins");
    console.log("labelId", labelId);

    let addedItems;
    addedItems = [...articleLabelItems, { labelId: labelId }];

    console.log("addedItems", addedItems);

    setArticleLabelItems(addedItems);
  };

  const handleDeleteArticleLabelJoins = (labelId) => {
    console.log("handleDeleteArticleLabelJoins");
    console.log("labelId", labelId);

    let filteredItems;
    filteredItems = articleLabelItems.filter((item) => {
      return item.labelId !== labelId;
    });
    console.log("filteredItems", filteredItems);
    setArticleLabelItems(filteredItems);
  };

  useEffect(() => {
    getLabels();

    const variables = {
      owner: user.username,
    };

    const createLabelSubscription = API.graphql(
      graphqlOperation(onCreateLabel, variables)
    ).subscribe({
      next: (msg) => {
        console.log(
          "msg.value.data.onCreateLabel",
          msg.value.data.onCreateLabel
        );
        getLabels();
        console.log("labels", labels);
      },
      error: (err) => {
        console.log("err", err);
        createLabelSubscription.unsubscribe();
      },
      close: () => {
        console.log("onDeleteLabel subscription done on ArticleDetail");
      },
    });

    const updateLabelSubscription = API.graphql(
      graphqlOperation(onUpdateLabel, variables)
    ).subscribe({
      next: (msg) => {
        console.log(
          "msg.value.data.onUpdateLabel",
          msg.value.data.onUpdateLabel
        );
        getLabels();
        console.log("labels", labels);
      },
      error: (err) => {
        console.log("err", err);
        updateLabelSubscription.unsubscribe();
      },
      close: () => {
        console.log("onDeleteLabel subscription done on ArticleDetail");
      },
    });

    const deleteLabelSubscription = API.graphql(
      graphqlOperation(onDeleteLabel, variables)
    ).subscribe({
      next: (msg) => {
        console.log(
          "msg.value.data.onDeleteLabel",
          msg.value.data.onDeleteLabel
        );
        getLabels();
        console.log("labels", labels);
      },
      error: (err) => {
        console.log("err", err);
        deleteLabelSubscription.unsubscribe();
      },
      close: () => {
        console.log("onDeleteLabel subscription done on ArticleDetail");
      },
    });

    const createArticleLabelJoinSubscription = API.graphql(
      graphqlOperation(onCreateArticleLabelJoin, variables)
    ).subscribe({
      next: (msg) => {
        console.log("createArticleLabelJoinSubscription");
        console.log(
          "msg.value.data.onCreateArticleLabelJoin",
          msg.value.data.onCreateArticleLabelJoin
        );
        // setArticleLabelItems([msg.value.data.onCreateLabel,...articleLabelItems]);
        fetchArticle();
      },
      error: (err) => {
        console.log("err", err);
        createArticleLabelJoinSubscription.unsubscribe();
      },
      close: () => {
        console.log(
          "onCreateArticleLabelJoin subscription done on ArticleDetail"
        );
      },
    });

    const deleteArticleLabelJoinSubscription = API.graphql(
      graphqlOperation(onDeleteArticleLabelJoin, variables)
    ).subscribe({
      next: (msg) => {
        console.log("deleteArticleLabelJoinSubscription");
        console.log(
          "msg.value.data.onDeleteArticleLabelJoin",
          msg.value.data.onDeleteArticleLabelJoin
        );
        // var pos=labels.map(x => x.id).indexOf(msg.value.data.onDeleteArticleLabelJoin.id)
        // console.log('pos',pos)
        // articleLabelItems.splice(pos, 1)
        // setArticleLabelItems(articleLabelItems);
        fetchArticle();
      },
      error: (err) => {
        console.log("err", err);
        deleteArticleLabelJoinSubscription.unsubscribe();
      },
      close: () => {
        console.log(
          "onDeleteArticleLabelJoin subscription done on ArticleDetail"
        );
      },
    });

    return () => {
      createLabelSubscription.unsubscribe();
      updateLabelSubscription.unsubscribe();
      deleteLabelSubscription.unsubscribe();
      createArticleLabelJoinSubscription.unsubscribe();
      deleteArticleLabelJoinSubscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log("labels", labels);
    !!labels && labels.length > 0 && updateLabelLength(labels.length);
  }, [labels]);

  return (
    <React.Fragment>
      <div className="label_select">
        {!!labels &&
          labels.length > 0 &&
          labels.map((item, i) => (
            <React.Fragment key={item.id}>
              {i >= 0 && (
                <React.Fragment>
                  {articleLabelItems.findIndex(
                    (articleLabelItem) => articleLabelItem.labelId === item.id
                  ) != -1 ? (
                    <Chip
                      styleName="label-chip edit"
                      label={"#" + item.name}
                      size="small"
                      onDelete={() => handleDeleteArticleLabelJoins(item.id)}
                      style={{
                        backgroundColor: item.color,
                        color: item.color,
                      }}
                      onClick={() => handleDeleteArticleLabelJoins(item.id)}
                      className="label_chip_on"
                    />
                  ) : (
                    <Chip
                      styleName="label-chip edit"
                      label={"#" + item.name}
                      size="small"
                      onClick={() => handleAddArticleLabelJoins(item.id)}
                      className="label_chip_off"
                    />
                  )}
                  {/* <IconButton styleName="edit-icon" aria-label="edit" onClick={(event) => openEditDialog(item)}> */}
                  <IconButton
                    styleName="edit-icon"
                    aria-label="edit"
                    onClick={() => {
                      console.log("refsEditLabelDialog", refsEditLabelDialog);
                      if (
                        !!refsEditLabelDialog.current[i] &&
                        !!refsEditLabelDialog.current[i].current
                      ) {
                        refsEditLabelDialog.current[i].current.setFormState({
                          ...refsEditLabelDialog.current[i].current.formState,
                          id: item.id,
                          name: item.name,
                          color: item.color,
                          owner: item.owner,
                          postedDate: item.postedDate,
                        });
                        refsEditLabelDialog.current[
                          i
                        ].current.handleClickOpen();
                      }
                    }}
                    size="large"
                  >
                    {/* <IconButton styleName="edit-icon" aria-label="edit" onClick={() => {
                          console.log('refsEditLabelDialog', refsEditLabelDialog)
                        }}> */}
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <LabelDialog
                    ref={refsEditLabelDialog.current[i]}
                    title_id="edit_label"
                    label={item}
                    mode="update"
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
      </div>
      <div>
        <Link
          styleName="next-to-previous"
          onClick={() => {
            console.log(refCreateDialog);
            refCreateDialog.current.handleClickOpen();
          }}
          to={{}}
          className="create_label"
        >
          <Translate id="create_label" />
        </Link>
        <LabelDialog
          ref={refCreateDialog}
          mode="create"
          title_id="create_label"
        />
      </div>
    </React.Fragment>
  );
}

export default CSSModules(LabelEdit, { ...style }, { allowMultiple: true });
