import { createTheme, adaptV4Theme } from "@mui/material/styles";

const darkTheme = createTheme(
  adaptV4Theme({
    palette: {
      mode: "dark",
      primary: {
        main: "#666666",
      },
      secondary: {
        main: "#bbbbbb",
      },
    },
    // typography: {
    //   h1: {
    //     fontSize: "1.3rem",
    //     fontWeight: 600,
    //   },
    //   h2: {
    //     fontSize: "1.1rem",
    //     fontWeight: 400,
    //   },
    //   fontFamily: "sans-serif",
    //   fontStyle: "italic",
    // },
    contrastThreshold: 4,
    tonalOffset: 0.8,
  })
);

export default darkTheme;
