import React, { useContext } from "react";
import PersistentDrawerLeft from "../../components/part/PersistentDrawerLeft";
import CreateArticleFab from "../../components/part/CreateArticleFab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ThemeContext } from "../../theme/themeContext";
import Clock from "../../components/part/Clock";

import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import Translate from "../../components/part/Translate";

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";

import CSSModules from "react-css-modules";
import style from "./Setting.module.scss";

export default CSSModules(Setting, { ...style }, { allowMultiple: true });

function Setting() {
  const themeContext = useContext(ThemeContext);

  const onThemeNameChange = (event) => {
    console.log("inputValue", event.target.value);
    themeContext.handleThemeChange(event.target.value);
    console.log("themeContext", themeContext);
  };

  const onLanguageChange = (event) => {
    localStorage.setItem("lang", event.target.value);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <PersistentDrawerLeft type="setting">
        <div styleName="main-context">
          <Clock />
          <br />
          {/* theme */}
          <InputLabel id="teheme-select-label">
            <Translate id="theme" />
          </InputLabel>
          <Select
            labelId="teheme-select-label"
            id="teheme-select"
            value={themeContext.themeName}
            onChange={onThemeNameChange}
          >
            <MenuItem value={"SEASON"}>
              <Translate id="theme.season" />
            </MenuItem>
            <MenuItem value={"DARK"}>
              <Translate id="theme.dark" />
            </MenuItem>
            <MenuItem value={"DEFAULT"}>
              <Translate id="theme.simple" />
            </MenuItem>
            <MenuItem value={"SOLARISED"}>
              <Translate id="theme.solarized" />
            </MenuItem>
            <MenuItem value={"SOLARISEDDARK"}>
              <Translate id="theme.solarizedDark" />
            </MenuItem>
          </Select>
          <FormHelperText>
            <Translate id="theme.description" />
          </FormHelperText>

          <br />
          {/* lang */}
          <InputLabel id="language-select-label">
            <Translate id="language" />
          </InputLabel>
          <Select
            labelId="language-select-label"
            id="lang-select"
            value={
              localStorage.getItem("lang")
                ? localStorage.getItem("lang")
                : navigator.language.split(/[-_]/)[0]
            }
            onChange={onLanguageChange}
          >
            <MenuItem value={"en"}>English</MenuItem>
            <MenuItem value={"ja"}>日本語</MenuItem>
            <MenuItem value={"zh"}>简体中文</MenuItem>
            <MenuItem value={"es"}>Español</MenuItem>
            <MenuItem value={"hi"}>हिन्दी</MenuItem>
            <MenuItem value={"ar"}>العربية</MenuItem>
          </Select>
          <FormHelperText>
            <Translate id="language.description" />
          </FormHelperText>
        </div>
      </PersistentDrawerLeft>
    </React.Fragment>
  );
}
