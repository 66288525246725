import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m6Theme = createTheme(adaptV4Theme({
  // jun　梅雨あじさい　水色、紫
  // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=B3E5FC&secondary.color=7E57C2
  palette: {
    primary: {
      light: "#e6ffff",
      main: "#b3e5fc",
      dark: "#82b3c9",
      contrastText: "#000000",
    },
    secondary: {
      light: "#b085f5",
      main: "#7e57c2",
      dark: "#4d2c91",
      contrastText: "#ffffff",
    },
  },
}));

export default m6Theme;
