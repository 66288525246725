import React, { lazy, Suspense, useState, useEffect } from "react";

import makeStyles from '@mui/styles/makeStyles';
import {
  Button,
  List,
  ListItem,
  Divider,
  ListItemText,
  CircularProgress,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import BottomNavigation from "@mui/material/BottomNavigation";
import MuiPagination from '@mui/material/Pagination';
import withStyles from '@mui/styles/withStyles';

// import ArticleItem from "../../components/part/ArticleItem";
import Translate from "../../components/part/Translate";
import ArticleSearch from "../page/ArticleSearch";

const useStyles = makeStyles((theme) => ({
  alignCenter: {
    textAlign: "center",
  },
  maxWidth: {
    width: "100%",
  },
  listHeader: {
    position: "sticky",
    top: 0,
    // zIndex: 1200,
    // backgroundColor: '#15202B',
    // borderBottom: '1px solid #37444C',
  },

  // https://codesandbox.io/s/spro6?file=/demo.js:341-628
  root: {
    // width: '80%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    // position: 'relative',
    // overflow: 'auto',
    // maxHeight: 300,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    // padding: 0,
  },

  // 画面下部に貼り付け
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
}));

export default function ArticleList({
  isLoading,
  articles,
  filterLabelName,
  getAdditionalArticles,
  articlesNextToken,
  scrollToPrevious,
  page,
  setPage,
}) {
  const classes = useStyles();

  const ArticleItem = lazy(() => import("../../components/part/ArticleItem"));

  const Pagination = withStyles({
    root: {
      display: "inline-block", //中央寄せのためインラインブロックに変更
    },
  })(MuiPagination);

  const changePage = (page, length) => {
    console.debug("changePage");

    if (articlesNextToken !== null && page >= Math.floor(length / PER_PAGE)) {
      if (previousLength != articles.length) {
        setPreviousLength(articles.length);
        console.debug("getAdditionalArticles");
        getAdditionalArticles();
      }
    } else {
      console.debug("loading...");
    }
    sessionStorage.setItem("articlesOffset", 0);
    setPage(page);
  };

  const PER_PAGE = 5;

  const [previousLength, setPreviousLength] = useState(0);

  const [articleFilterdLength, setArticleFilterdLength] = useState(0);

  React.useEffect(() => {
    // if (page * PER_PAGE > arguments.length) {
    //   let _page = Math.ceil(arguments.length / PER_PAGE);
    //   // changePage(_page, articleFilterdLength);
    //   setPage(_page);
    // }
    const length = articles.filter(
      (article) =>
        !!article &&
        (!filterLabelName ||
          article.labels.items.find((item) => {
            if (!!item && !!item.label && !!item.label.name)
              return item.label.name === filterLabelName;
          }))
    ).length;
    // console.debug("length", length);
    setArticleFilterdLength(length);
  }, [articles, page, filterLabelName]);

  return (
    <React.Fragment>
      <div id="timeline_root">
        {/* <List className={classes.root} subheader={<li />}>
            {[0, 1, 2, 3, 4].map((sectionId) => (
              <li key={`section-${sectionId}`} className={classes.listSection}>
                <ul className={classes.ul}>
                  <ListSubheader>{`I'm sticky ${sectionId}`}</ListSubheader>
                  {[0, 1, 2].map((item) => (
                    <ListItem button key={`item-${sectionId}-${item}`}>
                            <ListItemAvatar>
                            <Avatar>
                              <ImageIcon />
                            </Avatar>
                          </ListItemAvatar>
                      <ListItemText primary={`Item ${item} : Item ${item}`} secondary="Jan 9, 2014" />
                    </ListItem>
                  
                  ))}
                </ul>
              </li>
            ))}
          </List> */}

        <List disablePadding>
          {/* {console.log("ArticleList articles", articles)} */}
          {/* {console.log("ArticleList articles.length", articles.length)} */}
          {!!articles.length &&
            articles
              .filter(
                (article) =>
                  !!article &&
                  (!filterLabelName ||
                    article.labels.items.find((item) => {
                      if (!!item && !!item.label && !!item.label.name)
                        return item.label.name === filterLabelName;
                    }))
              )
              .map(
                (article, index) =>
                  (page - 1) * PER_PAGE <= index &&
                  index < page * PER_PAGE && (
                    <React.Fragment key={article.id}>
                      <Suspense
                        fallback={
                          <Container fixed>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="center"
                            >
                              <br />{" "}
                            </Grid>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="center"
                            >
                              <CircularProgress color="secondary" />
                            </Grid>
                          </Container>
                        }
                      >
                        <ArticleItem
                          article={article}
                          scrollToPrevious={scrollToPrevious}
                        />
                      </Suspense>
                      <Divider component="li" />
                    </React.Fragment>
                  )
              )}
          {Math.floor(
            articles
              .filter(
                (article) =>
                  !!article &&
                  (!filterLabelName ||
                    article.labels.items.find((item) => {
                      if (!!item && !!item.label && !!item.label.name)
                        return item.label.name === filterLabelName;
                    }))
              )
              .map(
                (article, index) =>
                  (page - 1) * PER_PAGE <= index && index < page * PER_PAGE
              ).length / PER_PAGE
          ) <= page
            ? changePage(page, articleFilterdLength)
            : console.debug(
                "Unnecessary changePage"
                // ,
                // Math.floor(
                //   articles
                //     .filter(
                //       (article) =>
                //         !!article &&
                //         (!filterLabelName ||
                //           article.labels.items.find((item) => {
                //             if (!!item && !!item.label && !!item.label.name)
                //               return item.label.name === filterLabelName;
                //           }))
                //     )
                //     .map(
                //       (article, index) =>
                //         (page - 1) * PER_PAGE <= index &&
                //         index < page * PER_PAGE
                //     ).length / PER_PAGE
                // )
              )}
          <ListItem
            alignItems="flex-start"
            className={classes.alignCenter}
            key="loadmore"
          ></ListItem>
        </List>
        <div style={{ height: "38px", width: "auto" }} />
      </div>
      <BottomNavigation className={classes.stickToBottom}>
        <div style={{ textAlign: "center", margin: "auto" }}>
          <Pagination
            count={Math.ceil(articleFilterdLength / PER_PAGE)} //総ページ数
            color="primary" //ページネーションの色
            onChange={(e, page) => {
              changePage(page, articleFilterdLength);
            }} //変更されたときに走る関数。第2引数にページ番号が入る
            page={page} //現在のページ番号
          />
        </div>
      </BottomNavigation>
    </React.Fragment>
  );
}
