import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m10Theme = createTheme(adaptV4Theme({
  palette: {
    // oct　ハロウィン10/31　オレンジ紫
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFB74D&secondary.color=6A1B9A
    primary: {
      light: "#ffe97d",
      main: "#ffb74d",
      dark: "#c88719",
      contrastText: "#000000",
    },
    secondary: {
      light: "#9c4dcc",
      main: "#6a1b9a",
      dark: "#38006b",
      contrastText: "#ffffff",
    },
  },
}));

export default m10Theme;
