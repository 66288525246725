import Dialog from "@mui/material/Dialog";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    height: auto;
  }
  .MuiDialog-paper {
    background-color: rgba(25, 25, 25, 0.6);
  }
`;

export default StyledDialog;
