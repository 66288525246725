import React from "react";
import { memo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useAuthDataContext } from "../containers/AuthDataProvider";
import ArticlesBySpecifiedUser from "../components/page/ArticlesBySpecifiedUser";
import ArticleSearch from "../components/page/ArticleSearch";
import Calendar from "../components/page/Calendar";
// import ArticleCRUD from "../components/page/ArticleCRUD";
import Setting from "../components/page/Setting";
import Map from "../components/page/Map";

import ArticleDetail from "../components/page/ArticleDetail";

import MediaPage from "../components/page/MediaPage";
import Login from "../components/page/Login";
import NotFound from "../components/page/NotFound";
import PrivacyPolicy from "../components/page/PrivacyPolicy";

import { useTracking } from "../hooks/useTracking";

const PrivateRoute = ({ component, ...options }) => {
  console.log("PrivateRoute start");
  const user = useAuthDataContext();
  const finalComponent = user ? component : Login;

  return <Route {...options} component={finalComponent} />;
};

const PrivateRedirect = ({ ...options }) => {
  console.log("PrivateRedirect start");
  const user = useAuthDataContext();
  if (!user) {
    console.log("public");
    return <Login />;
  } else {
    console.log("private");
    return <Redirect {...options} />;
  }
};

const AuthRouter = memo(() => {
  const user = useAuthDataContext();
  // let user_id;
  // if (!!user) {
  //   console.log("user", user);
  //   console.log("user.attributes.sub", user.attributes.sub);
  //   user_id = user.attributes.sub;
  // } else {
  //   user_id = "";
  // }

  useTracking("UA-78584240-5");
  useTracking("G-MQY2CM30G1");

  return (
    <Switch>
      {/* URLでマッチさせたい要素を書いていく */}
      {/* component={Home}とかでもOK。今回はWrapMainContentでラッパーしている。詳細後述。 */}
      {/* exactを入れることで厳密なURL比較が可能に */}
      {/* <PrivateRoute exact path="/" render={() => <ArticlesBySpecifiedUser username={user.username} />} />
    <PrivateRoute path="/settings" render={() => <ArticleCRUD username={user.username} />}/>
    <PrivateRoute path="/search" render={() => <ArticleSearch username={user.username} />}/> */}
      {/* <PrivateRoute path={"/privacypolicy"} component={PrivacyPolicy}/> */}
      <Route path={"/privacypolicy"} component={PrivacyPolicy} />
      <PrivateRedirect exact from="/ja" to={"/timeline"} />
      <PrivateRedirect exact from="/zh" to={"/timeline"} />
      <PrivateRedirect exact from="/hi" to={"/timeline"} />
      <PrivateRedirect exact from="/es" to={"/timeline"} />
      <PrivateRedirect exact from="/ar" to={"/timeline"} />
      <PrivateRedirect exact from="/" to={"/timeline"} />
      <PrivateRoute
        exact
        path={"/timeline"}
        component={ArticlesBySpecifiedUser}
      />
      <PrivateRoute path={"/calendar"} component={Calendar} />
      <PrivateRoute path={"/search"} component={ArticleSearch} />
      <PrivateRoute path={"/media"} component={MediaPage} />
      {/* <PrivateRoute path={"/settings"} component={ArticleCRUD}/> */}
      <PrivateRoute path={"/settings"} component={Setting} />
      <PrivateRoute path={"/article/:article_id"} component={ArticleDetail} />
      <PrivateRoute path={"/map"} component={Map} />
      {/* <Route exact path="/" component={ArticlesBySpecifiedUser}/>
    <Route path="/search" component={ArticleSearch}/>
    <Route path="/settings" component={ArticleCRUD}/> */}
      {/* URLヒットしないときはNot Found画面を表示する */}
      {/* <PrivateRoute path={"/privacypolicy"} component={PrivacyPolicy}/> */}
      <PrivateRoute path="*" component={NotFound} />
    </Switch>
  );
});

export default AuthRouter;
