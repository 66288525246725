import { createTheme, adaptV4Theme } from "@mui/material/styles";

const defaultTheme = createTheme(
  adaptV4Theme({
    palette: {
      // https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=00796B&primary.color=F5F5F5
      primary: {
        // light: "#ffffff",
        main: "#d0d0d0",
        // dark: "#c2c2c2",
        // contrastText: "#bb002f",
        // contrastDefaultColor: "#f50057",
      },
      secondary: {
        // light: "#48a999",
        main: "#00796b",
        // dark: "#004c40",
        // contrastText: "#0095a8",
        // contrastDefaultColor: "#26c6da",
      },
      // background: {
      //   default: "#bdbdbd",
      // },
      // text: {
      //   primary: "#4527a0",
      //   secondary: "#000070",
      //   disabled: "#7953d2",
      //   hint: "#536dfe",
      // },
      contrastThreshold: 2,
      tonalOffset: 0.25,
    },
  })
);

export default defaultTheme;
