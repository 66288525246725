import React, { useRef, useReducer } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import ArticleForm from "./ArticleForm";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Zoom from "@mui/material/Zoom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Translate from "../../components/part/Translate";

import CSSModules from "react-css-modules";
import style from "./CreateArticleFab.module.css";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  addIcon: {
    position: "fixed !important",
    bottom: "35px",
    right: "20px",
    zIndex: "1001",
  },
  addIconRtl: {
    position: "fixed !important",
    bottom: "35px",
    left: "20px",
    zIndex: "1001",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default CSSModules(
  CreateArticleFab,
  { ...style },
  { allowMultiple: true }
);

function CreateArticleFab() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const refArticleForm = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [checked, setChecked] = React.useState(false);
  const handleSplashIcon = () => {
    setChecked(true);
    setTimeout(
      function () {
        setChecked(false);
      }.bind(this),
      2000
    );
  };

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [messageSnackbar, setMessageSnackbar] = React.useState("error");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleOpenSnackbar = (message) => {
    setOpenSnackbar(true);
    setMessageSnackbar(message);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // 二度押し防止
  const processing = useRef(false);

  return (
    <div>
      <Fab
        color="secondary"
        aria-label="add"
        className={clsx(
          {
            [classes.addIcon]: localStorage.getItem("lang") != "ar",
          },
          {
            [classes.addIconRtl]: localStorage.getItem("lang") == "ar",
          }
        )}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <Translate id="create_article" />
            </Typography>
            <IconButton
              id="create_article_submit"
              autoFocus
              color="inherit"
              onClick={() => {
                console.log("refArticleForm", refArticleForm);

                console.debug("processing.current:", processing.current);
                // 二度押し防止
                // 処理中(true)なら非同期処理せずに抜ける
                if (processing.current) {
                  console.debug("return");
                  return;
                }
                // 処理中フラグを上げる
                processing.current = true;
                // 疑似非同期処理
                setTimeout(() => {
                  // 処理中フラグを下げる
                  processing.current = false;
                }, 5000);
                refArticleForm.current.createArticleService();
              }}
              size="large"
            >
              <DoneIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <ArticleForm
          onClose={handleClose}
          handleSplashIcon={handleSplashIcon}
          setOpenBackdrop={setOpenBackdrop}
          handleOpenSnackbar={handleOpenSnackbar}
          ref={refArticleForm}
        />
      </Dialog>
      <Zoom in={checked} timeout={{ appear: 0, enter: 500, exit: 300 }}>
        <CheckCircleIcon
          color="secondary"
          styleName="splash-icon"
        ></CheckCircleIcon>
        {/* <DeleteIcon color="secondary" styleName="splash-icon"></DeleteIcon> */}
      </Zoom>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        // autoHideDuration={6000}
      >
        <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
          {messageSnackbar}
        </Alert>
      </Snackbar>
    </div>
  );
}
