import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import {
  Button,
  List,
  ListItem,
  Divider,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import ImageCardOpenArticle from "./ImageCardOpenArticle";

import Translate from "../../components/part/Translate";

import CSSModules from "react-css-modules";
import style from "./AttachmentList.module.scss";

// const useStyles = makeStyles(theme => ({

//   alignCenter: {
//     textAlign: 'center',
//   },
//   loader: {
//     textAlign: 'center',
//     paddingTop: 20,
//   },
//   maxWidth: {
//     width: '100%',
//   },
//   listHeader: {
//     position: 'sticky',
//     top: 0,
//     // zIndex: 1200,
//     // backgroundColor: '#15202B',
//     // borderBottom: '1px solid #37444C',
//   },
// // https://codesandbox.io/s/spro6?file=/demo.js:341-628
//   root: {
//     // width: '80%',
//     // maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//     // position: 'relative',
//     // overflow: 'auto',
//     // maxHeight: 300,
//   },
//   listSection: {
//     backgroundColor: 'inherit',
//   },
//   ul: {
//     backgroundColor: 'inherit',
//     // padding: 0,
//   },

// }));

export default CSSModules(
  AttachmentList,
  { ...style },
  { allowMultiple: true }
);

function AttachmentList({
  isLoading,
  articles,
  getAdditionalArticles,
  articlesNextToken,
  filterLabelName,
}) {
  const [attachments, setAttachments] = useState([]);

  const [previousAttachmentsLength, setPreviousAttachmentsLength] = useState(0);
  const [pageChanged, setPageChanged] = useState(true);

  const changePage = () => {
    console.debug("changePage");
    setPageChanged(true);

    if (articlesNextToken !== null) {
      console.debug("getAdditionalArticles");
      getAdditionalArticles();
    }
  };

  useEffect(() => {
    let _attachments = [];

    let filteredArticles = !!filterLabelName
      ? articles.filter(function (article) {
          console.log("article", article);
          let findLabel;
          findLabel = article.labels.items.find(function (item) {
            if (!item.label) {
              return false;
            }
            // console.log("item.label.name:", item.label.name);
            return item.label.name === filterLabelName;
          });
          return !!findLabel;
        })
      : articles;

    // console.log("filteredArticles", filteredArticles);

    filteredArticles.forEach(function (article) {
      // console.log("article.attachments.items", article.attachments.items);
      _attachments = _attachments.concat(article.attachments.items);
      console.log("_attachments", _attachments);
    });

    console.log("_attachments", _attachments);
    setAttachments(_attachments);
    // console.log("attachments", attachments);

    if (pageChanged) {
      console.log("attachments.length", attachments.length);
      console.log("previousAttachmentsLength", previousAttachmentsLength);
      if (attachments.length > previousAttachmentsLength) {
        setPageChanged(false);
        setPreviousAttachmentsLength(attachments.length);
      } else {
        changePage();
      }
    }
  }, [articles, filterLabelName]);

  return (
    <React.Fragment>
      {!!attachments && !!attachments.length ? (
        <Grid container spacing={0}>
          {attachments.map((attachment) => (
            <Grid
              item
              xs={6}
              sm={3}
              md={2}
              alignItems="center"
              justifyContent="center"
            >
              {attachment.hasOwnProperty("path") && (
                <ImageCardOpenArticle
                  fileKey={attachment.path}
                  contentType={attachment.contentType}
                  articleId={attachment.articleId}
                  user_id={attachment.owner}
                />
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>添付ファイルが存在しません</div>
      )}

      {articlesNextToken !== null && (
        <Button
          variant="outlined"
          onClick={(e) => {
            changePage();
          }} //変更されたときに走る関数。第2引数にページ番号が入る
          styleName="next-button"
        >
          {" "}
          <Translate id="read_more" />{" "}
        </Button>
      )}
    </React.Fragment>
  );
}
