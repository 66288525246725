import { createTheme, adaptV4Theme } from "@mui/material/styles";

export const theme = createTheme(adaptV4Theme({
  palette: {
    //クリスマスカラー
    primary: {
      light: "#60ad5e",
      main: "#2e7d32",
      dark: "#005005",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#ff6434",
      main: "#dd2c00",
      dark: "#a30000",
      contrastText: "#ffffff",
    },
    // primary: {
    //   light: '#ffff8b',
    //   main: '#ffee58',
    //   dark: '#c9bc1f',
    //   contrastText: '#000000',
    // },
    // secondary: {
    //   light: '#63a4ff',
    //   main: '#1976d2',
    //   dark: '#004ba0',
    //   contrastText: '#ffffff',
    // },
  },
}));
