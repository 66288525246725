import React from "react";
import Chip from "@mui/material/Chip";

import CSSModules from "react-css-modules";
import style from "./LabelView.module.scss";

export default CSSModules(LabelView, { ...style }, { allowMultiple: true });

function LabelView(props) {
  const { articleLabelItems } = props;

  return (
    <React.Fragment>
      {!!articleLabelItems &&
        articleLabelItems.length > 0 &&
        articleLabelItems.map(
          (item, i) =>
            item.hasOwnProperty("label") &&
            !!item.label && (
              <React.Fragment key={item.label.id}>
                <Chip
                  styleName="label-chip"
                  label={"#" + item.label.name}
                  size="small"
                  style={{
                    backgroundColor: item.label.color,
                    color: item.label.color,
                  }}
                />
              </React.Fragment>
            )
        )}
    </React.Fragment>
  );
}
