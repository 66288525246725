import React, { useState } from "react";
import darkTheme from "./darkTheme";
import defaultTheme from "./defaultTheme";
import solarizedDarkTheme from "./solarizedDarkTheme";
import solarizedTheme from "./solarizedTheme";
import m1Theme from "./m1Theme";
import m2Theme from "./m2Theme";
import m3Theme from "./m3Theme";
import m4Theme from "./m4Theme";
import m5Theme from "./m5Theme";
import m6Theme from "./m6Theme";
import m7Theme from "./m7Theme";
import m8Theme from "./m8Theme";
import m9Theme from "./m9Theme";
import m10Theme from "./m10Theme";
import m11Theme from "./m11Theme";
import m12Theme from "./m12Theme";

export const ThemeContext = React.createContext({
  // このように設定するとIDEの補完が機能する
  theme: null,
  handleThemeChange: () => {},
  themeName: null,
});

const ThemeContextProvider = (props) => {
  let selectedTheme;
  let selectedThemeName;

  selectedTheme = defaultTheme;
  selectedTheme.typography.h6.fontWeight = 700;

  const [theme, setTheme] = useState(defaultTheme);
  const [themeName, setThemeName] = useState("DEFAULT");

  const [date, setDate] = useState(new Date());

  const handleThemeChange = (themeName) => {
    localStorage.setItem("theme", themeName);
    switch (themeName) {
      case "SEASON":
      default:
        var month = date.getMonth();
        console.log("date:", date);
        selectedThemeName = "SEASON";
        switch (month + 1) {
          case 1:
            console.log("case:1");
            selectedTheme = m1Theme;
            break;
          case 2:
            console.log("case:2");
            selectedTheme = m2Theme;
            break;
          case 3:
            console.log("case:3");
            selectedTheme = m3Theme;
            break;
          case 4:
            console.log("case:4");
            selectedTheme = m4Theme;
            break;
          case 5:
            console.log("case:5");
            selectedTheme = m5Theme;
            break;
          case 6:
            console.log("case:6");
            selectedTheme = m6Theme;
            break;
          case 7:
            console.log("case:7");
            selectedTheme = m7Theme;
            break;
          case 8:
            console.log("case:8");
            selectedTheme = m8Theme;
            break;
          case 9:
            console.log("case:9");
            selectedTheme = m9Theme;
            break;
          case 10:
            console.log("case:10");
            selectedTheme = m10Theme;
            break;
          case 11:
            console.log("case:11");
            selectedTheme = m11Theme;
            break;
          case 12:
            console.log("case:12");
            selectedTheme = m12Theme;
            break;
        }
        break;
      case "DARK":
        selectedThemeName = "DARK";
        selectedTheme = darkTheme;
        break;
      case "DEFAULT":
        selectedThemeName = "DEFAULT";
        selectedTheme = defaultTheme;
        break;
      case "SOLARISED":
        selectedThemeName = "SOLARISED";
        selectedTheme = solarizedTheme;
        break;
      case "SOLARISEDDARK":
        selectedThemeName = "SOLARISEDDARK";
        selectedTheme = solarizedDarkTheme;
        break;
    }

    console.log("selectedThemeName", selectedThemeName);
    setThemeName(selectedThemeName);
    console.log("selectedTheme", selectedTheme);
    selectedTheme.typography.h6.fontWeight = 700;
    setTheme(selectedTheme);
  };

  return (
    <ThemeContext.Provider
      value={
        localStorage.getItem("lang") != "ar"
          ? {
              handleThemeChange: handleThemeChange,
              theme: theme,
              themeName: themeName,
            }
          : {
              handleThemeChange: handleThemeChange,
              theme: theme,
              themeName: themeName,
              direction: "rtl",
            }
      }
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
