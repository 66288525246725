import React from "react";
import { FormattedDate } from "react-intl";

const Clock = (props) => {
  const [date, setDate] = React.useState(new Date());

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  //Replaces componentDidMount and componentWillUnmount
  React.useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <div>
      <FormattedDate
        value={date}
        year="numeric"
        month="long"
        day="2-digit"
        hour="numeric"
        minute="numeric"
        second="numeric"
        weekday="short"
      />
    </div>
  );
};

export default Clock;
