import React from "react";
import CssBaseline from "@mui/material/CssBaseline";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";

import CachedMediaDialog from "./CachedMediaDialog";

import CachedCardMedia from "./CachedCardMedia";

import CSSModules from "react-css-modules";
import style from "./ImageCard.module.scss";

export default CSSModules(ImageCard, { ...style }, { allowMultiple: true });

function ImageCard(props) {

  const { fileKey, contentType } = props;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Card onClick={handleClickOpen}>
        <CardActionArea styleName="card-action-area">
          <CachedCardMedia fileKey={fileKey} contentType={contentType}/>
        </CardActionArea>
      </Card>
      <CachedMediaDialog open={open} onClose={handleClose} image={fileKey} contentType={contentType}/>
    </React.Fragment>
  );
}
