import React, { useEffect, useState } from "react";
import clsx from "clsx";

import MailIcon from "@mui/icons-material/Mail";
import makeStyles from "@mui/styles/makeStyles";

import { Auth, Hub } from "aws-amplify";

import CSSModules from "react-css-modules";

import logo from "../../logo.svg";
import app from "../../App.module.scss";
import bootstrap from "../../bootstrap.module.css";
import style from "../../cognito-login.module.css";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TranslatedMessage from "../../components/part/Translate";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";

function Login() {
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  function signIn(provider) {
    console.log("signIn!!");
    const signInParam = { provider: provider };
    Auth.federatedSignIn(signInParam)
      .then((cred) => {
        // If success, you will get the AWS credentials
        console.log("cred", cred);
        return Auth.currentAuthenticatedUser();
      })
      .then((user) => {
        // If success, the user object you passed in Auth.federatedSignIn
        console.log("user", user);
      })
      .catch((e) => {
        console.log("catched exception", e);
      });
  }

  const [user, setUser] = useState(null);

  const settings = {
    autoPlay: true,
    infiniteLoop: true,
    // showArrows: false,
    showStatus: false,
    showThumbs: false,
    // showIndicators: true,
    // swipeable: true,
    // emulateTouch: true,
  };

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
    getUser().then((userData) => setUser(userData));
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (event, index) => {
    localStorage.setItem("lang", index);
  };

  return (
    <React.Fragment>
      <div id="login_page">
        <div styleName="global-header">
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <LanguageIcon />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            id="lang-menu"
          >
            <MenuItem
              component={Link}
              href="/en"
              id="menu-en"
              onClick={(event) => handleMenuItemClick(event, "en")}
            >
              English
            </MenuItem>
            <MenuItem
              component={Link}
              href="/ja"
              id="menu-ja"
              onClick={(event) => handleMenuItemClick(event, "ja")}
            >
              日本語
            </MenuItem>
            <MenuItem
              component={Link}
              href="/zh"
              id="menu-zh"
              onClick={(event) => handleMenuItemClick(event, "zh")}
            >
              简体中文
            </MenuItem>
            <MenuItem
              component={Link}
              href="/es"
              id="menu-es"
              onClick={(event) => handleMenuItemClick(event, "es")}
            >
              Español
            </MenuItem>
            <MenuItem
              component={Link}
              href="/hi"
              id="menu-hi"
              onClick={(event) => handleMenuItemClick(event, "hi")}
            >
              हिन्दी
            </MenuItem>
            <MenuItem
              component={Link}
              href="/ar"
              id="menu-ar"
              onClick={(event) => handleMenuItemClick(event, "ar")}
            >
              العربية
            </MenuItem>
          </Menu>
        </div>
        <header styleName="App-header">
          <img src={logo} styleName="App-logo" alt="logo" />
          <div styleName="App-subtitle">
            <Typography color="textSecondary" variant="body2">
              I want to keep using one diary forever
            </Typography>
          </div>
          {/* <div>username: {user ? user.username : "None"}</div> */}

          <button
            name="googleSignUpButton"
            onClick={() => {
              signIn("Google");
            }}
            styleName={clsx(
              "btn",
              "google-button",
              "socialButton-customizable",
              { rtl: localStorage.getItem("lang") == "ar" }
            )}
          >
            <span>
              <svg
                styleName="social-logo"
                viewBox="0 0 256 262"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
              >
                <path
                  d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                  fill="#4285F4"
                ></path>
                <path
                  d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                  fill="#34A853"
                ></path>
                <path
                  d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                  fill="#EA4335"
                ></path>
              </svg>
            </span>
            <span>
              &nbsp; &nbsp;
              <TranslatedMessage id="login.sign_in_with_google" />
            </span>
          </button>

          {/* <button
            name="facebookSignUpButton"
            onClick={() => {
              signIn("Facebook");
            }}
            styleName={clsx(
              "btn",
              "facebook-button",
              "socialButton-customizable",
              { rtl: localStorage.getItem("lang") == "ar" }
            )}
          >
            <span>
              <svg
                styleName="social-logo"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 216 216"
                color="#ffffff"
              >
                <path
                  fill="#ffffff"
                  d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"
                ></path>
              </svg>
            </span>
            <span>
              &nbsp; &nbsp;
              <TranslatedMessage id="login.sign_in_with_facebook" />
            </span>
          </button> */}

          <button
            name="MailSignUpButton"
            onClick={() => {
              signIn();
            }}
            styleName={clsx("btn", "mail-button", "socialButton-customizable", {
              rtl: localStorage.getItem("lang") == "ar",
            })}
          >
            <div styleName="social-logo">
              <MailIcon styleName="social-logo-icon" />
            </div>
            <span>
              &nbsp; &nbsp;
              <TranslatedMessage id="login.sign_in_with_email" />
            </span>
          </button>

          <div styleName="scroll">
            {/* <Typography color='textSecondary' variant='body2'>
                  Scroll
                  </Typography> */}
          </div>
        </header>

        <Grid container spacing={0} styleName="App-Container">
          <Grid item xs={12} sm={4}>
            <main styleName="App-Main">
              <Divider variant="middle" />
              <div styleName="text-area">
                <br />
                <Typography color="textSecondary" variant="body1">
                  <TranslatedMessage id="login.message1" />
                </Typography>
              </div>
              <div styleName="image-area">
                <img
                  border="0"
                  src="/images/2504878_camp_man.jpg"
                  alt="ポータビリティ"
                />
              </div>
            </main>
          </Grid>
          <Grid item xs={12} sm={4}>
            <main styleName="App-Main">
              <Divider variant="middle" />
              <div styleName="text-area">
                <br />
                <Typography color="textSecondary" variant="body1">
                  <TranslatedMessage id="login.message2" />
                  <br />
                  <br />
                </Typography>
              </div>
              <div styleName="image-area">
                <img
                  border="0"
                  src="/images/2504865_yoga_woman.jpg"
                  alt="パーソナリティ"
                />
              </div>
            </main>
          </Grid>
          <Grid item xs={12} sm={4}>
            <main styleName="App-Main">
              <Divider variant="middle" />
              <div styleName="text-area">
                <br />
                <Typography color="textSecondary" variant="body1">
                  <TranslatedMessage id="login.message3" />
                </Typography>
              </div>
              <div styleName="image-area">
                <img
                  border="0"
                  src="/images/2504846_plant_tree.jpg"
                  alt="永続性"
                />
              </div>
            </main>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          styleName="App-Container"
        >
          <Grid item xs={12} sm={12}>
            <Divider variant="middle" />
          </Grid>

          <Grid item xs={12} sm={4}>
            <main styleName="App-Main">
              <div styleName="text-area">
                <br />
                <Typography color="textSecondary" variant="body1">
                  <TranslatedMessage id="login.message.screenshots" />
                </Typography>
                <br />
              </div>
              <div styleName="carousel-area">
                <Carousel {...settings}>
                  <div>
                    <img
                      src={
                        "images/screenshots/article.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".1-C-2.created article.png"
                      }
                    />
                    <p className="legend">
                      <TranslatedMessage id="login.message.screenshots-legend1" />
                    </p>
                  </div>
                  <div>
                    <img
                      src={
                        "images/screenshots/article.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".1-D-2.article list.png"
                      }
                    />
                    <p className="legend">
                      <TranslatedMessage id="login.message.screenshots-legend2" />
                    </p>
                  </div>
                  <div>
                    <img
                      src={
                        "images/screenshots/article.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".1-D-3.side bar.png"
                      }
                    />
                    <p className="legend">
                      <TranslatedMessage id="login.message.screenshots-legend3" />
                    </p>
                  </div>
                  <div>
                    <img
                      src={
                        "images/screenshots/label.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".2-B-5.calendar page-orig.png"
                      }
                    />
                    <p className="legend">
                      <TranslatedMessage id="login.message.screenshots-legend4" />
                    </p>
                  </div>
                  <div>
                    <img
                      src={
                        "images/screenshots/article.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".1-D-4.media page-orig.png"
                      }
                    />
                    <p className="legend">
                      {" "}
                      <TranslatedMessage id="login.message.screenshots-legend5" />
                    </p>
                  </div>
                  <div>
                    <img
                      src={
                        "images/screenshots/label.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".2-B-4.map page-orig.png"
                      }
                    />
                    <p className="legend">
                      {" "}
                      <TranslatedMessage id="login.message.screenshots-legend6" />
                    </p>
                  </div>
                  <div>
                    <img
                      src={
                        "images/screenshots/label.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".2-C-2.select filter.png"
                      }
                    />
                    <p className="legend">
                      <TranslatedMessage id="login.message.screenshots-legend7" />
                    </p>
                  </div>
                  <div>
                    <img
                      src={
                        "images/screenshots/label.test_" +
                        localStorage.getItem("lang") +
                        ".js/" +
                        localStorage.getItem("lang") +
                        ".2-C-3.after filter.png"
                      }
                    />
                    <p className="legend">
                      <TranslatedMessage id="login.message.screenshots-legend8" />
                    </p>
                  </div>
                </Carousel>
              </div>
            </main>
          </Grid>
        </Grid>
        <main styleName="App-Main">
          <Divider variant="middle" />
          <br />
          <br />
          <br />
          <br />
          <div styleName="text-area">
            <Typography color="textSecondary" variant="body2">
              <TranslatedMessage id="login.message4" />
            </Typography>
            <br />
          </div>

          <div styleName="image-area">
            <button
              name="googleSignUpButton"
              onClick={() => {
                signIn("Google");
              }}
              styleName={clsx(
                "btn",
                "google-button",
                "socialButton-customizable",
                { rtl: localStorage.getItem("lang") == "ar" }
              )}
            >
              <span>
                <svg
                  styleName="social-logo"
                  viewBox="0 0 256 262"
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="xMidYMid"
                >
                  <path
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    fill="#34A853"
                  ></path>
                  <path
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    fill="#EA4335"
                  ></path>
                </svg>
              </span>
              <span>
                &nbsp; &nbsp;
                <TranslatedMessage id="login.sign_in_with_google" />
              </span>
            </button>
            {/* <button
              name="facebookSignUpButton"
              onClick={() => {
                signIn("Facebook");
              }}
              styleName={clsx(
                "btn",
                "facebook-button",
                "socialButton-customizable",
                { rtl: localStorage.getItem("lang") == "ar" }
              )}
            >
              <span>
                <svg
                  styleName="social-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 216 216"
                  color="#ffffff"
                >
                  <path
                    fill="#ffffff"
                    d="
          M204.1 0H11.9C5.3 0 0 5.3 0 11.9v192.2c0 6.6 5.3 11.9 11.9
          11.9h103.5v-83.6H87.2V99.8h28.1v-24c0-27.9 17-43.1 41.9-43.1
          11.9 0 22.2.9 25.2 1.3v29.2h-17.3c-13.5 0-16.2 6.4-16.2
          15.9v20.8h32.3l-4.2 32.6h-28V216h55c6.6 0 11.9-5.3
          11.9-11.9V11.9C216 5.3 210.7 0 204.1 0z"
                  ></path>
                </svg>
              </span>
              <span>
                &nbsp; &nbsp;
                <TranslatedMessage id="login.sign_in_with_facebook" />
              </span>
            </button> */}

            <button
              name="MailSignUpButton"
              onClick={() => {
                signIn();
              }}
              styleName={clsx(
                "btn",
                "mail-button",
                "socialButton-customizable",
                { rtl: localStorage.getItem("lang") == "ar" }
              )}
            >
              <div styleName="social-logo">
                <MailIcon styleName="social-logo-icon" />
              </div>
              <span>
                &nbsp; &nbsp;
                <TranslatedMessage id="login.sign_in_with_email" />
              </span>
            </button>
          </div>
        </main>

        <footer styleName="App-footer">
          <Link href="/privacypolicy" styleName="login-privacypolicy">
            <TranslatedMessage id="privacypolicy" />
          </Link>
        </footer>
      </div>
    </React.Fragment>
  );
}

// export default Login;
export default CSSModules(
  Login,
  { ...app, ...style, ...bootstrap },
  { allowMultiple: true }
);
