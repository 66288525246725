import React, {
  useState,
  useEffect,
  useRef,
  lazy,
  Suspense,
  useReducer,
} from "react";
import { useAuthDataContext } from "../../containers/AuthDataProvider";
import PersistentDrawerLeft from "../../components/part/PersistentDrawerLeft";
import CreateArticleFab from "../../components/part/CreateArticleFab";
import ArticleProvider from "../part/ArticleProvider";
import ArticleList from "../../components/part/ArticleList";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import "../part/CKEditor_custom.scss";

export default function ArticlesBySpecifiedUser(props) {
  // console.log("start ArticlesBySpecifiedUser");
  const user = useAuthDataContext();

  const [isLoading, setIsLoading] = useState(true);

  const ARTICLE_CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
  const ARTICLE_UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
  const ARTICLE_DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
  const ARTICLE_INITIAL_QUERY = "INITIAL_QUERY";
  const ARTICLE_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const articleReducer = (state, action) => {
    switch (action.type) {
      case ARTICLE_INITIAL_QUERY:
        setIsLoading(false);
        return action.articles;
      case ARTICLE_ADDITIONAL_QUERY:
        setIsLoading(false);
        return [...state, ...action.articles];
      case ARTICLE_CREATE_SUBSCRIPTION:
        return [action.article, ...state];
      case ARTICLE_UPDATE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1, action.article);
        console.log("renew state", state);
        return [...state];
      case ARTICLE_DELETE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1);
        console.log("renew state", state);
        return [...state];
      default:
        return state;
    }
  };

  const [articles, dispatchArticle] = useReducer(articleReducer, []);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articles"));
    if (storageVar) {
      dispatchArticle(storageVar);
    }
  }, []);

  const [articlesNextToken, setArticlesNextToken] = useState(null);
  useEffect(() => {
    sessionStorage.setItem(
      "articlesNextToken",
      JSON.stringify(articlesNextToken)
    );
  }, [articlesNextToken]);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articlesNextToken"));
    if (storageVar) {
      setArticlesNextToken(storageVar);
    }
  }, []);

  const refArticleAttachmentProvider = useRef();
  const refPersistentDrawerLeft = useRef();

  const [articlesOffset, setArticlesOffset] = useState(null);
  useEffect(() => {
    sessionStorage.setItem("articlesOffset", JSON.stringify(articlesOffset));
  }, [articlesOffset]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("articlesOffset"));
    if (storageVar) {
      setArticlesOffset(storageVar);
    }
  }, []);

  function scrollToPrevious() {
    console.log("scrollToPrevious artcle");
    console.log("articlesOffset", sessionStorage.getItem("articlesOffset"));
    window.scrollTo({
      top: sessionStorage.getItem("articlesOffset"),
    });
  }

  function putScrollSession() {
    console.log("putScrollSession article");
    let _offset =
      window.pageYOffset ||
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    console.log("_offset", _offset);
    // setArticlesOffset(_offset);
    // なぜかこれだと、useEffectのreturn時(unmount時)にセットできないので、下の行のように直接sessionStorageにセットした
    sessionStorage.setItem("articlesOffset", _offset);
    // console.log("articlesOffset", articlesOffset);
    console.log("articlesOffset", sessionStorage.getItem("articlesOffset"));
  }

  const handleLabelFileter = (e) => {
    const { labelName, labelColor } = e.currentTarget.dataset;
    setFilterLabelName(labelName);
    setFilterLabelColor(labelColor);
    setPage(1);
  };

  const [filterLabelName, setFilterLabelName] = useState("");
  useEffect(() => {
    sessionStorage.setItem("filterLabelName", JSON.stringify(filterLabelName));
  }, [filterLabelName]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelName"));
    if (storageVar) {
      setFilterLabelName(storageVar);
    }
  }, []);

  const [filterLabelColor, setFilterLabelColor] = useState("");
  useEffect(() => {
    sessionStorage.setItem(
      "filterLabelColor",
      JSON.stringify(filterLabelColor)
    );
  }, [filterLabelColor]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelColor"));
    if (storageVar) {
      setFilterLabelColor(storageVar);
    }
  }, []);

  //ページ番号
  const [page, setPage] = useState(1);
  useEffect(() => {
    sessionStorage.setItem("timelinepage", JSON.stringify(page));
  }, [page]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("timelinepage"));
    if (storageVar) {
      setPage(storageVar);
    }
  }, []);

  sessionStorage.setItem("prevPage", "/timeline");

  return (
    <React.Fragment>
      <PersistentDrawerLeft
        type="timeline"
        handleLabelFileter={handleLabelFileter}
        filterLabelName={filterLabelName}
        filterLabelColor={filterLabelColor}
        ref={refPersistentDrawerLeft}
        setIsLoading={setIsLoading}
      >
        <ArticleProvider
          ref={refArticleAttachmentProvider}
          user={user}
          articles={articles}
          dispatchArticle={dispatchArticle}
          articlesNextToken={articlesNextToken}
          setArticlesNextToken={setArticlesNextToken}
          putScrollSession={putScrollSession}
          scrollToPrevious={scrollToPrevious}
          setIsLoading={setIsLoading}
        ></ArticleProvider>

        {!!refArticleAttachmentProvider.current && (
          <ArticleList
            isLoading={isLoading}
            articles={articles}
            filterLabelName={filterLabelName}
            getAdditionalArticles={
              refArticleAttachmentProvider.current.getAdditionalArticles
            }
            articlesNextToken={articlesNextToken}
            scrollToPrevious={scrollToPrevious}
            page={page}
            setPage={setPage}
          />
        )}
        {!refArticleAttachmentProvider.current && (
          <Container fixed>
            <Grid container alignItems="center" justifyContent="center">
              <br />{" "}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <br />{" "}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <br />{" "}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <br />{" "}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <br />{" "}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <br />{" "}
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <CircularProgress color="secondary" />
            </Grid>
          </Container>
        )}
        <CreateArticleFab />
      </PersistentDrawerLeft>
    </React.Fragment>
  );
}
