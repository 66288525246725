import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m3Theme = createTheme(adaptV4Theme({
  palette: {
    // mar　ひな祭り　桃、赤
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=FFCDD2&secondary.color=D50000&primary.text.color=000000
    primary: {
      light: "#ffffff",
      main: "#ffcdd2",
      dark: "#cb9ca1",
      contrastText: "#000000",
    },
    secondary: {
      light: "#ff5131",
      main: "#d50000",
      dark: "#9b0000",
      contrastText: "#ffffff",
    },
  },
}));

export default m3Theme;
