import { createTheme, adaptV4Theme } from "@mui/material/styles";
const m8Theme = createTheme(adaptV4Theme({
  // aug　海　青
  // https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=1976D2&primary.color=29B6F6
  palette: {
    primary: {
      light: "#73e8ff",
      main: "#29b6f6",
      dark: "#0086c3",
      contrastText: "#000000",
    },
    secondary: {
      light: "#63a4ff",
      main: "#1976d2",
      dark: "#004ba0",
      contrastText: "#ffffff",
    },
  },
}));

export default m8Theme;
