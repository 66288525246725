import React, {
  useState,
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { ja, ar, es, hi, zhCN, enUS } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  createAttachment,
  createArticle,
  createArticleLabelJoin,
} from "../../graphql/mutations";

import { useAuthDataContext } from "../../containers/AuthDataProvider";
import InputImageWithPreview from "../../components/part/InputImageWithPreview";

import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { UserSetting } from "../../App";

import { animateScroll as scroll } from "react-scroll";

import LabelEdit from "../../components/part/LabelEdit";
import InputLocation from "../../components/part/InputLocation";
import "../part/CKEditor_custom.scss";

import { useIntl } from "react-intl";
const defaults = require("../../translations/en.json");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "66px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "74px",
    },
    "& > *": {
      margin: theme.spacing(1),
      width: "95vw",
    },
  },
}));

const initialState = {
  name: "",
  description: "",
  postedDate: new Date().toISOString(),
};

const ArticleForm = forwardRef((props, ref) => {
  const { onClose, handleSplashIcon, setOpenBackdrop, handleOpenSnackbar } =
    props;
  const user = useAuthDataContext();
  const { formatMessage } = useIntl();

  const [formState, setFormState] = useState(initialState);

  const fileRef = useRef();

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  // const handlePostedDateChange = (newValue: Dayjs | null) => {
  const handlePostedDateChange = (newValue) => {
    setInput("postedDate", newValue);
  };

  function createArticleLabelJoinAPI(postDate, labelId) {
    let articleId = postDate + user.username;

    try {
      const articleLabelJoinVariables = {
        input: {
          articleId: articleId,
          labelId: labelId,
          postedDate: postDate,
          owner: user.username,
        },
      };
      console.log("articleLabelJoinVariables", articleLabelJoinVariables);

      API.graphql(
        graphqlOperation(createArticleLabelJoin, articleLabelJoinVariables)
      )
        .then((result) => {
          console.log("createArticleLabelJoin result", result);
        })
        .catch((err) => console.log("err", err));
    } catch (err) {
      console.log("error creating ArticleLabelJoins:", err);
    } finally {
      console.log(" created ArticleLabelJoins!");
    }
  }

  async function createArticleLabelJoinService(postDate) {
    for (let k = 0; k < labels.length; k++) {
      // console.log('labels['+ k + ']',labels[k]);
      // console.log(
      //   'articleLabelItems.some(i => i.labelId === labels[k].id)',
      //   articleLabelItems.some(i => i.labelId === labels[k].id)
      // );

      if (articleLabelItems.some((i) => i.labelId === labels[k].id)) {
        // console.log('only after labels['+ k + ']',labels[k]);
        createArticleLabelJoinAPI(postDate, labels[k].id);
      }
    }
  }

  async function createArticleAPI(postDate) {
    let articleId = postDate + user.username;

    const variables = {
      input: {
        ...formState,
        id: articleId,
        owner: user.username,
        latitude: latitude,
        longitude: longitude,
        positionName: positionName,
      },
    };
    console.log("variables", variables);
    setFormState(initialState);

    API.graphql(graphqlOperation(createArticle, variables))
      .then((result) => {
        console.log("await1");
        console.log("result", result);
        console.log(
          "result.data.createArticle.id",
          result.data.createArticle.id
        );
        console.log("articleId", articleId);
        handleSplashIcon();
        setOpenBackdrop(false);
      })
      .catch((err) => {
        console.log("err", err);

        setOpenBackdrop(false);

        if (err.errors === undefined) {
          console.log("err.code", err.code);
          console.log("err.message", err.message);
          handleOpenSnackbar("" + err.code + ":" + err.message);
        } else {
          console.log("err.errors", err.errors);
          console.log("err.errors[0].message", err.errors[0].message);
          handleOpenSnackbar(err.errors[0].message);
        }
      });

    console.log("labels", labels);
    console.log("articleLabelItems", articleLabelItems);
  }

  //チェック対象のする拡張子
  var allow_exts = new Array("jpg", "jpeg", "png");

  //アップロード予定のファイル名の拡張子を確認する関数
  function checkExt(filename) {
    //比較のため小文字にする
    var ext = getExt(filename).toLowerCase();
    //許可する拡張子の一覧(allow_exts)から対象の拡張子があるか確認する
    if (allow_exts.indexOf(ext) === -1) return false;
    return true;
  }

  //ファイル名から拡張子を取得する関数
  function getExt(filename) {
    var pos = filename.lastIndexOf(".");
    if (pos === -1) return "";
    return filename.slice(pos + 1);
  }

  useImperativeHandle(ref, () => ({
    createArticleService() {
      console.log("addArticle");
      setOpenBackdrop(true);
      try {
        // if (!formState.name || !formState.description) return;

        // console.log('fileRef.current.files', fileRef.current.files);
        let postDate = formState.postedDate;

        const length = fileRef.current.files.length;
        if (length > 0) {
          var j = 0;
          for (var i = 0; i < fileRef.current.files.length; i++) {
            var fileName = fileRef.current.files[i].name;
            var fileType = fileRef.current.files[i].type;

            // 拡張子を確認し、直前にサムネイルではないことを示すprefixを追加する
            // s3登録時をトリガに、サムネイルではないことを示すprefixがあることをチェックして、
            // lambda で s3にサムネイルを作成する
            if (checkExt(fileName)) {
              var pos = fileName.lastIndexOf(".");
              if (pos !== -1) {
                var fileExt = fileName.slice(pos + 1);
                console.log("fileExt", fileExt);
                var fileBody = fileName.slice(0, pos);
                console.log("fileBody", fileBody);
                fileName = fileBody + "-o." + fileExt;
              }
            }

            console.log("fileName", fileName);
            console.log("fileType", fileType);

            Storage.put(postDate + fileName, fileRef.current.files[i], {
              level: "private",
              contentType: fileType,
              // cacheControl: 'max-age=604800'
            })
              .then((result) => {
                console.log("result", result);
              })
              .catch((err) => console.log("err", err))
              .finally((result) => {
                j++;
                if (j === length) {
                  createArticleLabelJoinService(postDate);
                  createArticleAPI(postDate);
                }
              });

            const attachmentVariables = {
              input: {
                contentType: fileType,
                path: postDate + fileName,
                postedDate: postDate,
                owner: user.username,
                articleId: postDate + user.username,
              },
            };

            API.graphql(graphqlOperation(createAttachment, attachmentVariables))
              .then((result) => {
                console.log("result", result);
              })
              .catch((err) => console.log("err", err));
          }
        } else {
          createArticleLabelJoinService(postDate);
          createArticleAPI(postDate);
        }
      } catch (err) {
        console.log("error creating article:", err);
      } finally {
        onClose();
        scroll.scrollToTop();
      }
    },
  }));

  const classes = useStyles();

  const { switchState, setSwitchState, changeSwitch } = useContext(UserSetting);

  const [labels, setLabels] = useState([]);
  const [articleLabelItems, setArticleLabelItems] = useState([]);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [positionName, setPositionName] = useState(null);

  function setPositionSet(_latitude, _longitude, _positionName) {
    setLatitude(_latitude);
    setLongitude(_longitude);
    setPositionName(_positionName);
  }

  const editorConfiguration = {
    // plugins: [Markdown],
    toolbar: {
      // viewportTopOffset: 56,
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bulletedList",
        "numberedList",
        // "todoList",
        // "|",
        // "fontfamily",
        // "fontsize",
        // "|",
        // "alignment",
        // // "|",
        // "fontColor",
        // "fontBackgroundColor",
        // "|",
        "bold",
        "italic",
        // "strikethrough",
        // "underline",
        // "subscript",
        // "superscript",
        // "uploadImage",
        "|",
        "blockQuote",
        "code",
        "codeBlock",
        "|",
        "link",
        // "|",
        // "outdent",
        // "indent",
        // "|",
        "|",
        "insertTable",
      ],
    },
  };

  const editorConfigurationRtl = {
    contentsLangDirection: "rtl",
    language: {
      // The UI will be in Arbic.
      ui: "ar",

      // The content will be edited in Arabic.
      content: "ar",
    },
    // plugins: [Markdown],
    toolbar: {
      // viewportTopOffset: 56,
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bulletedList",
        "numberedList",
        // "todoList",
        // "|",
        // "fontfamily",
        // "fontsize",
        // "|",
        // "alignment",
        // // "|",
        // "fontColor",
        // "fontBackgroundColor",
        // "|",
        "bold",
        "italic",
        // "strikethrough",
        // "underline",
        // "subscript",
        // "superscript",
        // "uploadImage",
        "|",
        "blockQuote",
        "code",
        "codeBlock",
        "|",
        "link",
        // "|",
        // "outdent",
        // "indent",
        // "|",
        "|",
        "insertTable",
      ],
    },
  };

  //langの選択状況に応じてdate-fnsのロケールを切り替える
  const switchLocale = () => {
    switch (localStorage.getItem("lang")) {
      case "ar":
        return ar;
      case "hi":
        return hi;
      case "es":
        return es;
      case "ja":
        return ja;
      case "zh":
        return zhCN;
      default:
        return enUS;
    }
  };

  return (
    <div
      id="articleform_root"
      className={classes.root}
      noValidate
      autoComplete="off"
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={switchLocale()}
      >
        <DateTimePicker
          label={formatMessage({
            id: "date",
            defaultMessage: defaults["date"],
          })}
          id="postedDate"
          value={formState.postedDate}
          onChange={handlePostedDateChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>

      <TextField
        margin="dense"
        id="name"
        label={formatMessage({
          id: "title",
          defaultMessage: defaults["title"],
        })}
        type="text"
        onChange={(event) => setInput("name", event.target.value)}
        value={formState.name}
        fullWidth
      />

      {switchState ? (
        <TextField
          margin="dense"
          id="description"
          label={formatMessage({
            id: "description",
            defaultMessage: defaults["description"],
          })}
          type="text"
          onChange={(event) => setInput("description", event.target.value)}
          value={formState.description}
          fullWidth
          multiline
        />
      ) : (
        <CKEditor
          editor={ClassicEditor}
          config={
            localStorage.getItem("lang") != "ar"
              ? editorConfiguration
              : editorConfigurationRtl
          }
          data={formState.description}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            // console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            // console.log({ event, editor, data });
            setInput("description", data);
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      )}

      {/* <FormControlLabel
        className="switch_html"
        control={
          <Switch checked={switchState} onChange={changeSwitch} name="html" />
        }
        label="HTML"
      /> */}

      <LabelEdit
        labels={labels}
        setLabels={setLabels}
        articleLabelItems={articleLabelItems}
        setArticleLabelItems={setArticleLabelItems}
        user={user}
      />

      <InputImageWithPreview fileRef={fileRef} />

      <InputLocation
        latitude={latitude}
        longitude={longitude}
        positionName={positionName}
        setPositionSet={setPositionSet}
        setPositionName={setPositionName}
      />
    </div>
  );
});

export default ArticleForm;
