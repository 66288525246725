import React, { useState, useEffect, useRef, useReducer } from "react";
import { useAuthDataContext } from "../../containers/AuthDataProvider";
import PersistentDrawerLeft from "../../components/part/PersistentDrawerLeft";
import CreateArticleFab from "../../components/part/CreateArticleFab";

import { Button } from "@mui/material";
import ArticleProvider from "../part/ArticleProvider";

import Translate from "../../components/part/Translate";

import Leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import SelectLocation from "../../components/part/SelectLocation";

import CSSModules from "react-css-modules";
import style from "./Map.module.scss";
import "./map.css";
export default CSSModules(Map, { ...style }, { allowMultiple: true });

/* エラーテキスト */
const ErrorText = () => (
  <p className="App-error-text">geolocation IS NOT available</p>
);

function Map(props) {
  const user = useAuthDataContext();
  const user_id = user.attributes.sub;

  // ラベル・フィルターの追加
  const refPersistentDrawerLeft = useRef();
  const handleLabelFileter = (e) => {
    const { labelName, labelColor } = e.currentTarget.dataset;
    setFilterLabelName(labelName);
    setFilterLabelColor(labelColor);
  };

  const [filterLabelName, setFilterLabelName] = useState("");
  useEffect(() => {
    sessionStorage.setItem("filterLabelName", JSON.stringify(filterLabelName));
  }, [filterLabelName]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelName"));
    if (storageVar) {
      setFilterLabelName(storageVar);
    }
  }, []);

  const [filterLabelColor, setFilterLabelColor] = useState("");
  useEffect(() => {
    sessionStorage.setItem(
      "filterLabelColor",
      JSON.stringify(filterLabelColor)
    );
  }, [filterLabelColor]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("filterLabelColor"));
    if (storageVar) {
      setFilterLabelColor(storageVar);
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const ARTICLE_CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
  const ARTICLE_UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
  const ARTICLE_DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
  const ARTICLE_INITIAL_QUERY = "INITIAL_QUERY";
  const ARTICLE_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const articleReducer = (state, action) => {
    switch (action.type) {
      case ARTICLE_INITIAL_QUERY:
        setIsLoading(false);
        return action.articles;
      case ARTICLE_ADDITIONAL_QUERY:
        setIsLoading(false);
        return [...state, ...action.articles];
      case ARTICLE_CREATE_SUBSCRIPTION:
        return [action.article, ...state];
      case ARTICLE_UPDATE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1, action.article);
        console.log("renew state", state);
        return [...state];
      case ARTICLE_DELETE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1);
        console.log("renew state", state);
        return [...state];
      default:
        return state;
    }
  };

  const [articles, dispatchArticle] = useReducer(articleReducer, []);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articles"));
    if (storageVar) {
      dispatchArticle(storageVar);
    }
  }, []);

  const [articlesNextToken, setArticlesNextToken] = useState(null);
  useEffect(() => {
    sessionStorage.setItem(
      "articlesNextToken",
      JSON.stringify(articlesNextToken)
    );
  }, [articlesNextToken]);
  useEffect(() => {
    const storageVar = JSON.parse(localStorage.getItem("articlesNextToken"));
    if (storageVar) {
      setArticlesNextToken(storageVar);
    }
  }, []);

  const refArticleAttachmentProvider = useRef();

  function putScrollSession() {}
  function scrollToPrevious() {}

  //////////

  const [isAvailable, setAvailable] = useState(false);

  // useEffectが実行されているかどうかを判定するために用意しています
  const isFirstRef = useRef(true);

  /*
   * ページ描画時にGeolocation APIが使えるかどうかをチェックしています
   * もし使えなければその旨のエラーメッセージを表示させます
   */
  useEffect(() => {
    isFirstRef.current = false;
    if ("geolocation" in navigator) {
      setAvailable(true);
    }
  }, [isAvailable]);

  // useEffect実行前であれば、"Loading..."という呼び出しを表示させます
  if (isFirstRef.current && refArticleAttachmentProvider.current)
    return <div className="App">Loading...</div>;

  Leaflet.Icon.Default.imagePath =
    "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/";

  sessionStorage.setItem("prevPage", "/map");

  return (
    <React.Fragment>
      <ArticleProvider
        ref={refArticleAttachmentProvider}
        user={user}
        articles={articles}
        dispatchArticle={dispatchArticle}
        articlesNextToken={articlesNextToken}
        setArticlesNextToken={setArticlesNextToken}
        putScrollSession={putScrollSession}
        scrollToPrevious={scrollToPrevious}
        setIsLoading={setIsLoading}
      ></ArticleProvider>
      <PersistentDrawerLeft
        type="map"
        handleLabelFileter={handleLabelFileter}
        filterLabelName={filterLabelName}
        filterLabelColor={filterLabelColor}
        ref={refPersistentDrawerLeft}
        setIsLoading={setIsLoading}
      >
        {!isFirstRef && !isAvailable && <ErrorText />}
        {isAvailable && (
          <React.Fragment>
            <SelectLocation
              articles={articles}
              user_id={user_id}
              filterLabelName={filterLabelName}
            />
          </React.Fragment>
        )}
        {articlesNextToken !== null && (
          <Button
            variant="outlined"
            onClick={() =>
              refArticleAttachmentProvider.current.getAdditionalArticles()
            }
            styleName="read_more"
          >
            {" "}
            <Translate id="read_more" />{" "}
          </Button>
        )}
        <CreateArticleFab />
      </PersistentDrawerLeft>
    </React.Fragment>
  );
}
