/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getArticle = /* GraphQL */ `
  query GetArticle($id: ID!) {
    getArticle(id: $id) {
      id
      name
      description
      owner
      postedDate
      attachments {
        items {
          id
          contentType
          path
          owner
          articleId
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      labels {
        items {
          id
          articleId
          labelId
          label {
            id
            name
            color
            owner
            postedDate
            createdAt
            updatedAt
          }
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      positionName
      latitude
      longitude
      createdAt
      updatedAt
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      contentType
      path
      owner
      articleId
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentType
        path
        owner
        articleId
        postedDate
        article {
          id
          name
          description
          owner
          postedDate
          positionName
          latitude
          longitude
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLabel = /* GraphQL */ `
  query GetLabel($id: ID!) {
    getLabel(id: $id) {
      id
      name
      color
      owner
      postedDate
      articles {
        items {
          id
          articleId
          labelId
          owner
          postedDate
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLabels = /* GraphQL */ `
  query ListLabels(
    $filter: ModelLabelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getArticleLabelJoin = /* GraphQL */ `
  query GetArticleLabelJoin($id: ID!) {
    getArticleLabelJoin(id: $id) {
      id
      articleId
      labelId
      owner
      postedDate
      article {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
        }
        labels {
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      label {
        id
        name
        color
        owner
        postedDate
        articles {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listArticleLabelJoins = /* GraphQL */ `
  query ListArticleLabelJoins(
    $filter: ModelArticleLabelJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticleLabelJoins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        articleId
        labelId
        owner
        postedDate
        article {
          id
          name
          description
          owner
          postedDate
          positionName
          latitude
          longitude
          createdAt
          updatedAt
        }
        label {
          id
          name
          color
          owner
          postedDate
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listArticlesByDate = /* GraphQL */ `
  query ListArticlesByDate(
    $owner: String
    $postedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelArticleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticlesByDate(
      owner: $owner
      postedDate: $postedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        owner
        postedDate
        attachments {
          nextToken
          items {
            id
            contentType
            path
            owner
            articleId
            postedDate
            article {
              id
              name
              description
              owner
              postedDate
              positionName
              latitude
              longitude
              createdAt
              updatedAt
            }
          }
        }
        labels {
          items {
            id
            articleId
            labelId
            label {
              id
              name
              color
              owner
              postedDate
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        positionName
        latitude
        longitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAttachmentsByDate = /* GraphQL */ `
  query ListAttachmentsByDate(
    $owner: String
    $postedDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachmentsByDate(
      owner: $owner
      postedDate: $postedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        path
        owner
        articleId
        postedDate
        article {
          id
          name
          description
          owner
          postedDate
          labels {
            items {
              id
              articleId
              labelId
              label {
                id
                name
                color
                owner
                postedDate
                createdAt
                updatedAt
              }
              owner
              postedDate
              createdAt
              updatedAt
            }
            nextToken
          }
          positionName
          latitude
          longitude
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
