import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useReducer,
} from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Zoom from "@mui/material/Zoom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { FormattedDate } from "react-intl";
import Badge from "@mui/material/Badge";

import clsx from "clsx";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { ja, ar, es, hi, zhCN, enUS } from "date-fns/locale";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  createAttachment,
  deleteAttachment,
  deleteArticle,
  updateArticle,
  createArticleLabelJoin,
  deleteArticleLabelJoin,
} from "../../graphql/mutations";
import { getArticle } from "../../graphql/queries";
// import { searchArticles } from "../graphql/queries";
import {
  onUpdateArticle,
  onDeleteAttachment,
} from "../../graphql/subscriptions";

import PersistentDrawerLeft from "../../components/part/PersistentDrawerLeft";
import InputImageWithPreview from "../../components/part/InputImageWithPreview";
import AlertDialogSlide from "../../components/part/AlertDialogSlide";
import Moment from "react-moment";

import { useAuthDataContext } from "../../containers/AuthDataProvider";
import ArticleProvider from "../part/ArticleProvider";

import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";

import { Button, ListItemText, Typography } from "@mui/material";

import MediaThumbnail from "../part/MediaThumbnail";

import { useHistory } from "react-router-dom";
import ImageCard from "../part/ImageCard";
import LabelView from "../../components/part/LabelView";
import LabelEdit from "../../components/part/LabelEdit";
import ViewLocation from "../../components/part/ViewLocation";
import InputLocation from "../../components/part/InputLocation";

import CSSModules from "react-css-modules";
import style from "./ArticleDetail.module.scss";
import "../part/CKEditor_custom.scss";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Switch from "@mui/material/Switch";
import { UserSetting } from "../../App";

import Translate from "../../components/part/Translate";
import { useIntl } from "react-intl";
const defaults = require("../../translations/en.json");

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "66px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "74px",
    },
    "& > *": {
      margin: theme.spacing(1),
      width: "95vw",
    },
  },
  description: {
    whiteSpace: "pre-wrap",
  },
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  title: {
    flexGrow: 1,
  },
}));

const initialState = { name: "", description: "" };

export default CSSModules(ArticleDetail, { ...style }, { allowMultiple: true });

function ArticleDetail(props) {
  const user = useAuthDataContext();
  const { formatMessage } = useIntl();

  const ARTICLE_CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
  const ARTICLE_UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
  const ARTICLE_DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
  const ARTICLE_INITIAL_QUERY = "INITIAL_QUERY";
  const ARTICLE_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const articleReducer = (state, action) => {
    switch (action.type) {
      case ARTICLE_INITIAL_QUERY:
        return action.articles;
      case ARTICLE_ADDITIONAL_QUERY:
        return [...state, ...action.articles];
      case ARTICLE_CREATE_SUBSCRIPTION:
        return [action.article, ...state];
      case ARTICLE_UPDATE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1, action.article);
        console.log("renew state", state);
        return [...state];
      case ARTICLE_DELETE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.article", action.article);
        var pos = state.map((x) => x.id).indexOf(action.article.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1);
        console.log("renew state", state);
        return [...state];
      default:
        return state;
    }
  };

  const [articles, dispatchArticle] = useReducer(articleReducer, []);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("articles"));
    if (storageVar) {
      dispatchArticle(storageVar);
    }
  }, []);

  const [articlesNextToken, setArticlesNextToken] = useState(null);
  useEffect(() => {
    sessionStorage.setItem(
      "articlesNextToken",
      JSON.stringify(articlesNextToken)
    );
  }, [articlesNextToken]);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("articlesNextToken"));
    if (storageVar) {
      setArticlesNextToken(storageVar);
    }
  }, []);

  const ATTACHMENT_CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION";
  const ATTACHMENT_UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
  const ATTACHMENT_DELETE_SUBSCRIPTION = "DELETE_SUBSCRIPTION";
  const ATTACHMENT_INITIAL_QUERY = "INITIAL_QUERY";
  const ATTACHMENT_ADDITIONAL_QUERY = "ADDITIONAL_QUERY";

  const attachmentReducer = (state, action) => {
    switch (action.type) {
      case ATTACHMENT_INITIAL_QUERY:
        return action.attachments;
      case ATTACHMENT_ADDITIONAL_QUERY:
        return [...state, ...action.attachments];
      case ATTACHMENT_CREATE_SUBSCRIPTION:
        return [action.attachment, ...state];
      case ATTACHMENT_UPDATE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.attachment", action.attachment);
        var pos = state.map((x) => x.id).indexOf(action.attachment.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1, action.attachment);
        console.log("renew state", state);
        return [...state];
      case ATTACHMENT_DELETE_SUBSCRIPTION:
        console.log("state", state);
        console.log("action.attachment", action.attachment);
        var pos = state.map((x) => x.id).indexOf(action.attachment.id);
        console.log("pos", pos);
        console.log("state", state);
        state.splice(pos, 1);
        console.log("renew state", state);
        return [...state];
      default:
        return state;
    }
  };

  const [attachments, dispatchAttachment] = useReducer(articleReducer, []);
  useEffect(() => {
    const storageVar = JSON.parse(sessionStorage.getItem("attachments"));
    if (storageVar) {
      dispatchAttachment(storageVar);
    }
  }, []);

  const [attachmentsNextToken, setAttachmentsNextToken] = useState(null);
  useEffect(() => {
    sessionStorage.setItem(
      "attachmentsNextToken",
      JSON.stringify(attachmentsNextToken)
    );
  }, [attachmentsNextToken]);
  useEffect(() => {
    const storageVar = JSON.parse(
      sessionStorage.getItem("attachmentsNextToken")
    );
    if (storageVar) {
      setAttachmentsNextToken(storageVar);
    }
  }, []);

  const [isLoading, setIsLoading] = useState(true);

  const refArticleAttachmentProvider = useRef();

  function putScrollSession() {}
  function scrollToPrevious() {}

  const refDeleteArticleDialog = useRef();
  const [attachmentLength, setAttachmentLength] = useState(3);
  const refsDeleteAttachmentDialog = useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  let history = useHistory();
  const ARTICLE_DEFAULT = {
    name: "",
    description: "",
    owner: "",
    postedDate: "",
  };

  const [editFormState, setEditFormState] = useState({ ...ARTICLE_DEFAULT });
  const [isEditing, setIsEditing] = useState(false);

  function setInputEdit(key, value) {
    setEditFormState({ ...editFormState, [key]: value });
    console.log("editFormState", editFormState);
  }

  // const handlePostedDateChange = (newValue: Dayjs | null) => {
  const handlePostedDateChange = (newValue) => {
    setInputEdit("postedDate", newValue);
  };

  const [article, setArticle] = useState({ ...ARTICLE_DEFAULT });

  const fileRef = useRef();

  const [articleLabelItems, setArticleLabelItems] = useState([]);
  const [initialArticleLabelItems, setInitialArticleLabelItems] = useState([]);

  function setPositionName(positionName) {
    setInputEdit("positionName", positionName);
  }

  function setPositionSet(_latitude, _longitude, _positionName) {
    // console.log('_latitude',_latitude)
    // console.log('_longitude',_longitude)
    // console.log('_positionName',_positionName)
    setEditFormState({
      ...editFormState,
      ["latitude"]: _latitude,
      ["longitude"]: _longitude,
      ["positionName"]: _positionName,
    });
  }

  const fetchArticle = async () => {
    const res = await API.graphql(
      graphqlOperation(getArticle, {
        id: props.match.params.article_id,
      })
    )
      .then((result) => {
        console.log("result", result);
        console.log("getArticle then article", result.data.getArticle);

        setArticle(result.data.getArticle);
        if (
          result.data.getArticle.hasOwnProperty("attachments") &&
          result.data.getArticle.attachments.hasOwnProperty("items") &&
          result.data.getArticle.attachments.items.length > 0
        ) {
          updateAttachmentLength(
            result.data.getArticle.attachments.items.length
          );
        }
        console.log(
          "getArticles then articleLabelItems",
          result.data.getArticle.labels.items
        );
        setArticleLabelItems(result.data.getArticle.labels.items);
        setInitialArticleLabelItems(result.data.getArticle.labels.items);
        console.log("articleLabelItems", articleLabelItems);

        var storageArticles = JSON.parse(sessionStorage.getItem("articles"));
        console.log("storageArticles", storageArticles);
        var pos = storageArticles
          .map((x) => x.id)
          .indexOf(result.data.getArticle.id);
        console.log("pos", pos);
        storageArticles.splice(pos, 1, result.data.getArticle);
        console.log("renew storageArticles", storageArticles);
        sessionStorage.setItem("articles", JSON.stringify(storageArticles));
      })
      .catch((err) => console.log("err", err))
      .finally((result) => {
        console.log("result", result);
        console.log("getArticles finally article", article);
      });
    // setNextToken(res.data.listArticlesByDate.nextToken);
    // setArticles(res.data.listArticlesByDate.items)
    // setNextNextToken(res.data.listArticlesByDate.nextToken)
    setIsLoading(false);
  };

  const editArticle = async () => {
    // async function deleteArticle(id) {
    console.log("editArticle");
    console.log("article", article);
    setEditFormState({
      ...editFormState,
      id: article.id,
      name: article.name,
      description: article.description,
      owner: article.owner,
      postedDate: article.postedDate,
      positionName: article.positionName,
      latitude: article.latitude,
      longitude: article.longitude,
    });
    setIsEditing(true);
    console.log("editFormState", editFormState);
  };

  function updateArticleAPI() {
    console.log("labels", labels);
    // console.log("initialArticleLabelItems", initialArticleLabelItems);
    // console.log("articleLabelItems", articleLabelItems);

    for (let k = 0; k < labels.length; k++) {
      // console.log("labels[" + k + "]", labels[k]);
      // console.log(
      //   "initialArticleLabelItems.some(i => i.labelId === labels[k].id)",
      //   initialArticleLabelItems.some((i) => i.labelId === labels[k].id)
      // );
      // console.log(
      //   "articleLabelItems.some(i => i.labelId === labels[k].id)",
      //   articleLabelItems.some((i) => i.labelId === labels[k].id)
      // );
      if (
        initialArticleLabelItems.some((i) => i.labelId === labels[k].id) &&
        !articleLabelItems.some((i) => i.labelId === labels[k].id)
      ) {
        // console.log(
        //   "deleteArticleLabelJoinAPI(labels[" + k + "].id);",
        //   labels[k]
        // );
        deleteArticleLabelJoinAPI(labels[k].id);
      } else if (
        !initialArticleLabelItems.some((i) => i.labelId === labels[k].id) &&
        articleLabelItems.some((i) => i.labelId === labels[k].id)
      ) {
        // console.log(
        //   "createArticleLabelJoinAPI(labels[" + k + "].id);",
        //   labels[k]
        // );
        createArticleLabelJoinAPI(labels[k].id);
      }
    }

    const article = { ...editFormState };
    console.log("article", article);

    const articleVariables = {
      input: { ...article },
    };
    console.log("articleVariables", articleVariables);
    setEditFormState(initialState);
    const updatedArticle = API.graphql(
      graphqlOperation(updateArticle, articleVariables)
    )
      .then((result) => {
        console.log("result", result);
        setOpenBackdrop(false);
        handleSplashIcon();
      })
      .catch((err) => {
        console.log("err", err);
        setOpenBackdrop(false);
        handleOpenSnackbar("" + err.errors);
      });
  }

  //チェック対象のする拡張子
  var allow_exts = new Array("jpg", "jpeg", "png");

  //アップロード予定のファイル名の拡張子を確認する関数
  function checkExt(filename) {
    //比較のため小文字にする
    var ext = getExt(filename).toLowerCase();
    //許可する拡張子の一覧(allow_exts)から対象の拡張子があるか確認する
    if (allow_exts.indexOf(ext) === -1) return false;
    return true;
  }

  //ファイル名から拡張子を取得する関数
  function getExt(filename) {
    var pos = filename.lastIndexOf(".");
    if (pos === -1) return "";
    return filename.slice(pos + 1);
  }

  const hundleUpdateArticle = async () => {
    setOpenBackdrop(true);
    console.log("hundleUpdateArticle");
    console.log("editFormState", editFormState);

    try {
      const article = { ...editFormState };
      // console.log('fileRef.current.files', fileRef.current.files);
      var postDate = new Date().toISOString();
      const files_length = fileRef.current.files.length;
      if (files_length > 0) {
        let j = 0;
        for (let i = 0; i < files_length; i++) {
          var fileName = fileRef.current.files[i].name;
          var fileType = fileRef.current.files[i].type;

          // 拡張子を確認し、直前にサムネイルではないことを示すprefixを追加する
          // s3登録時をトリガに、サムネイルではないことを示すprefixがあることをチェックして、
          // lambda で s3にサムネイルを作成する
          if (checkExt(fileName)) {
            var pos = fileName.lastIndexOf(".");
            if (pos !== -1) {
              var fileExt = fileName.slice(pos + 1);
              var fileBody = fileName.slice(0, pos - 1);
              fileName = fileBody + "-o." + fileExt;
            }
          }

          console.log("fileName", fileName);
          console.log("fileType", fileType);

          await Storage.put(postDate + fileName, fileRef.current.files[i], {
            level: "private",
            contentType: fileType,
            //  cacheControl: 'max-age=604800'
          })
            .then((result) => {
              console.log("result", result);
            })
            .catch((err) => console.log("err", err))
            .finally((result) => {
              j++;
              if (j == files_length) {
                updateArticleAPI();
              }
            });

          const attachmentVariables = {
            input: {
              contentType: fileType,
              path: postDate + fileName,
              postedDate: postDate,
              owner: user.username,
              articleId: article.id,
            },
          };

          const newAttachment = API.graphql(
            graphqlOperation(createAttachment, attachmentVariables)
          )
            .then((result) => {
              console.log("result", result);
            })
            .catch((err) => console.log("err", err));
        }
      } else {
        updateArticleAPI();
      }
    } catch (err) {
      console.log("error updating article:", err);
    } finally {
      setIsEditing(false);
      // fetchArticles();
    }
  };

  const hundleDeleteArticle = async () => {
    setOpenBackdrop(true);
    console.log("hundleDeleteArticle", article);

    try {
      var t = null;
      for (var i = 0; i < article.attachments.items.length; i++) {
        console.log(
          "article.attachments.items[i]",
          article.attachments.items[i]
        );
        hundleDeleteAttachment(article.attachments.items[i]);
      }

      const deleteVariables = {
        input: { id: article.id },
      };
      // const variables = {
      //   input: { ...article, postedDate: new Date().toISOString() ,owner: user.username},
      // }
      console.log("variables", deleteVariables);
      setEditFormState(initialState);
      const deletedArticle = await API.graphql(
        graphqlOperation(deleteArticle, deleteVariables)
      )
        .then((result) => {
          console.log("result", result);
          console.log("result!!!!!!!");

          var storageArticles = JSON.parse(sessionStorage.getItem("articles"));
          console.log("storageArticles", storageArticles);

          if (!!storageArticles) {
            var pos = storageArticles.map((x) => x.id).indexOf(article.id);
            console.log("pos", pos);
            storageArticles.splice(pos, 1);
            console.log("renew storageArticles", storageArticles);
            sessionStorage.setItem("articles", JSON.stringify(storageArticles));
          }
          setIsEditing(false);
          // onClose();
          handleDeleteSplashIcon();
        })
        .catch((err) => {
          setIsEditing(false);
          console.log("err", err);

          setOpenBackdrop(false);

          if (err.errors == undefined) {
            console.log("err.code", err.code);
            console.log("err.message", err.message);
            handleOpenSnackbar("" + err.code + ":" + err.message);
          } else {
            console.log("err.errors", "" + err.errors);
            handleOpenSnackbar("" + err.errors);
          }
        });
      console.log("deletedArticle", deletedArticle);
    } catch (err) {
      console.log("error deleting article:", err);
    } finally {
    }
  };

  function updateAttachmentLength(value) {
    // console.log("updateAttachmentLength",value)
    setAttachmentLength(value);
    refsDeleteAttachmentDialog.current =
      refsDeleteAttachmentDialog.current.splice(0, value);
    for (let i = 0; i < value; i++) {
      refsDeleteAttachmentDialog.current[i] =
        refsDeleteAttachmentDialog.current[i] || React.createRef();
    }
  }

  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState();

  const handleDeleteLabel = () => {
    console.log("handleDeleteLabel");
  };

  function createArticleLabelJoinAPI(labelId) {
    try {
      var postDate = new Date().toISOString();

      const articleLabelJoinVariables = {
        input: {
          articleId: article.id,
          labelId: labelId,
          postedDate: postDate,
          owner: user.username,
        },
      };
      console.log("articleLabelJoinVariables", articleLabelJoinVariables);

      const newArticleLabelJoin = API.graphql(
        graphqlOperation(createArticleLabelJoin, articleLabelJoinVariables)
      )
        .then((result) => {
          console.log("createArticleLabelJoin result", result);
        })
        .catch((err) => console.log("err", err));
    } catch (err) {
      console.log("error creating ArticleLabelJoins:", err);
    } finally {
      console.log(" created ArticleLabelJoins!");
    }
  }

  function deleteArticleLabelJoinAPI(labelId) {
    // console.log("initialArticleLabelItems", initialArticleLabelItems);
    // console.log("labelId", labelId);
    const filteredItems = initialArticleLabelItems.filter((item) => {
      return item.labelId == labelId;
    });

    for (var i = 0; i < filteredItems.length; i++) {
      try {
        const articleLabelJoinDeleteVariables = {
          input: {
            id: filteredItems[i].id,
          },
        };
        console.log(
          "articleLabelJoinDeleteVariables",
          articleLabelJoinDeleteVariables
        );

        const deletedArticleLabelJoin = API.graphql(
          graphqlOperation(
            deleteArticleLabelJoin,
            articleLabelJoinDeleteVariables
          )
        )
          .then((result) => {
            console.log("deleteteArticleLabelJoin result", result);
          })
          .catch((err) => console.log("err", err));
      } catch (err) {
        console.log("error deleting ArticleLabelJoins:", err);
      } finally {
        console.log("deleted ArticleLabelJoins!");
      }
    }
  }

  const [checked, setChecked] = React.useState(false);
  const handleSplashIcon = () => {
    setChecked(true);
    setTimeout(
      function () {
        setChecked(false);
      }.bind(this),
      2000
    );
  };

  const [openDeleteSplash, setOpenDeleteSplash] = React.useState(false);
  const handleDeleteSplashIcon = () => {
    setOpenDeleteSplash(true);
    setTimeout(
      function () {
        setOpenDeleteSplash(false);
        setOpenBackdrop(false);
        history.push("/timeline");
      }.bind(this),
      2000
    );
  };

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [messageSnackbar, setMessageSnackbar] = React.useState("error");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleOpenSnackbar = (message) => {
    setOpenSnackbar(true);
    setMessageSnackbar(message);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const { switchState, setSwitchState, changeSwitch } = useContext(UserSetting);

  useEffect(() => {
    fetchArticle();

    const variables = {
      owner: user.username,
    };

    const updateArticleSubscription = API.graphql(
      graphqlOperation(onUpdateArticle, variables)
    ).subscribe({
      next: (msg) => {
        console.log(
          "msg.value.data.onUpdateArticle",
          msg.value.data.onUpdateArticle
        );
        const article = msg.value.data.onUpdateArticle;
        fetchArticle();
      },
      error: (err) => {
        console.log("err", err);
        updateArticleSubscription.unsubscribe();
      },
      close: () => {
        console.log("onUpdateArticle subscription done on ArticleDetail");
        updateAttachmentLength(article.attachments.items.length);
      },
    });

    const deleteAttachmentSubscription = API.graphql(
      graphqlOperation(onDeleteAttachment, variables)
    ).subscribe({
      next: (msg) => {
        console.log(
          "msg.value.data.onDeleteAttachment",
          msg.value.data.onDeleteAttachment
        );
        const attachment = msg.value.data.onDeleteAttachment;
        // if (article.owner !== user.username) return;
        fetchArticle();
      },
      error: (err) => {
        console.log("err", err);
        deleteAttachmentSubscription.unsubscribe();
      },
      close: () => {
        console.log("deleteAttachment subscription done on ArticleDetail");
        updateAttachmentLength(article.attachments.items.length);
      },
    });

    !!article &&
      article.hasOwnProperty("attachments") &&
      article.attachments.hasOwnProperty("items") &&
      article.attachments.items.length > 0 &&
      updateAttachmentLength(article.attachments.items.length);

    return () => {
      updateArticleSubscription.unsubscribe();
      deleteAttachmentSubscription.unsubscribe();
    };
  }, [labels]);

  const hundleDeleteAttachment = (item) => {
    console.log("daleteAttachment item", item);
    console.log("daleteAttachment item.id", item.id);

    // 拡張子を確認し、サムネイルであるprefixに変換する
    var fileKey = item.path;
    var thumbailFileKey = fileKey;
    if (checkExt(fileKey)) {
      var pos = fileKey.lastIndexOf("-o.");
      if (pos !== -1) {
        var fileExt = fileKey.slice(pos + "-o.".length);
        var fileBody = fileKey.slice(0, pos);
        thumbailFileKey = fileBody + "-t." + fileExt;
        console.log("thumbailFileKey: ", thumbailFileKey);
      }
    }
    // サムネイル削除
    Storage.remove(thumbailFileKey, { level: "private" })
      .then((result) => console.log("サムネイル削除result", result))
      .catch((err) => console.log("サムネイル削除err", err));

    try {
      Storage.remove(item.path, { level: "private" })
        .then((result) => console.log("result", result))
        .catch((err) => console.log("err", err));

      const attachmentVariables = {
        input: {
          id: item.id,
        },
      };

      const res = API.graphql(
        graphqlOperation(deleteAttachment, attachmentVariables)
      )
        .then((result) => console.log("result", result))
        .catch((err) => console.log("err", err));
    } catch (err) {
      console.log("error del　ete article:", err);
    } finally {
      // setIsEditing(false);
      // fetchArticles();
      console.log("final delete article:");
    }
  };

  // 前ページに戻る
  const handlePrevPage = () => {
    let prevPage = sessionStorage.getItem("prevPage");
    history.push(prevPage);
  };

  // 二度押し防止
  const processing = useRef(false);

  const classes = useStyles();

  const editorConfiguration = {
    // plugins: [Markdown],
    toolbar: {
      viewportTopOffset: 56,
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bulletedList",
        "numberedList",
        // "todoList",
        // "|",
        // "fontfamily",
        // "fontsize",
        // "|",
        // "alignment",
        // // "|",
        // "fontColor",
        // "fontBackgroundColor",
        // "|",
        "bold",
        "italic",
        // "strikethrough",
        // "underline",
        // "subscript",
        // "superscript",
        // "uploadImage",
        "|",
        "blockQuote",
        "code",
        "codeBlock",
        "|",
        "link",
        // "|",
        // "outdent",
        // "indent",
        // "|",
        "|",
        "insertTable",
      ],
    },
  };

  const editorConfigurationRtl = {
    // plugins: [Markdown],
    // contentsLangDirection: "rtl",
    language: {
      // The UI will be English.
      ui: "ar",

      // But the content will be edited in Arabic.
      content: "ar",
    },
    toolbar: {
      viewportTopOffset: 56,
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bulletedList",
        "numberedList",
        // "todoList",
        // "|",
        // "fontfamily",
        // "fontsize",
        // "|",
        // "alignment",
        // // "|",
        // "fontColor",
        // "fontBackgroundColor",
        // "|",
        "bold",
        "italic",
        // "strikethrough",
        // "underline",
        // "subscript",
        // "superscript",
        // "uploadImage",
        "|",
        "blockQuote",
        "code",
        "codeBlock",
        "|",
        "link",
        // "|",
        // "outdent",
        // "indent",
        // "|",
        "|",
        "insertTable",
      ],
    },
  };

  //langの選択状況に応じてdate-fnsのロケールを切り替える
  const switchLocale = () => {
    switch (localStorage.getItem("lang")) {
      case "ar":
        return ar;
      case "hi":
        return hi;
      case "es":
        return es;
      case "ja":
        return ja;
      case "zh":
        return zhCN;
      default:
        return enUS;
    }
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar>
          {!isEditing ? (
            <IconButton
              color="inherit"
              aria-label="open prevpage"
              onClick={handlePrevPage}
              edge="start"
              className={clsx(classes.menuButton)}
              size="large"
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              aria-label="edit_cancel_icon"
              onClick={() => {
                setIsEditing(false);
              }}
              edge="start"
              className={clsx(classes.menuButton)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          )}
          <Typography variant="h5" noWrap className={classes.title}>
            {!!article && !!article.hasOwnProperty("name") && article.name}
          </Typography>
          {!isEditing ? (
            <IconButton
              id="edit_article_icon"
              color="inherit"
              onClick={() => {
                editArticle();
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          ) : (
            <React.Fragment>
              <IconButton
                id="delete_article_icon"
                color="inherit"
                onClick={() => {
                  // console.log("refArticleForm", refArticleForm);

                  console.debug("processing.current:", processing.current);
                  // 二度押し防止
                  // 処理中(true)なら非同期処理せずに抜ける
                  if (processing.current) {
                    console.debug("return");
                    return;
                  }
                  // 処理中フラグを上げる
                  processing.current = true;
                  // 疑似非同期処理
                  setTimeout(() => {
                    // 処理中フラグを下げる
                    processing.current = false;
                  }, 5000);
                  refDeleteArticleDialog.current.handleClickOpen();
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                id="update_article_icon"
                color="inherit"
                onClick={() => {
                  // console.log("refArticleForm", refArticleForm);

                  console.debug("processing.current:", processing.current);
                  // 二度押し防止
                  // 処理中(true)なら非同期処理せずに抜ける
                  if (processing.current) {
                    console.debug("return");
                    return;
                  }
                  // 処理中フラグを上げる
                  processing.current = true;
                  // 疑似非同期処理
                  setTimeout(() => {
                    // 処理中フラグを下げる
                    processing.current = false;
                  }, 5000);
                  hundleUpdateArticle();
                }}
                size="large"
              >
                <DoneIcon />
              </IconButton>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>

      <ArticleProvider
        ref={refArticleAttachmentProvider}
        user={user}
        articles={articles}
        dispatchArticle={dispatchArticle}
        articlesNextToken={articlesNextToken}
        setArticlesNextToken={setArticlesNextToken}
        putScrollSession={putScrollSession}
        scrollToPrevious={scrollToPrevious}
        setIsLoading={setIsLoading}
      ></ArticleProvider>
      <div id="article_detail">
        {!isEditing ? (
          <div className={classes.root} noValidate autoComplete="off">
            {!!article ? (
              <React.Fragment>
                {article.hasOwnProperty("attachments") &&
                  article.attachments.hasOwnProperty("items") &&
                  article.attachments.items.length > 0 && (
                    <ImageCard
                      fileKey={article.attachments.items[0].path}
                      contentType={article.attachments.items[0].contentType}
                    />
                  )}

                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography color="textSecondary" variant="body2">
                        {!!article.postedDate && (
                          <FormattedDate
                            value={article.postedDate}
                            year="numeric"
                            month="long"
                            day="2-digit"
                            hour="numeric"
                            minute="numeric"
                            weekday="short"
                          />
                        )}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        className={`title ${classes.name}`}
                      >
                        {article.name}
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <Typography color="textPrimary">
                      <div
                        styleName="view_description"
                        className="view_description"
                        dangerouslySetInnerHTML={{
                          __html: article.description,
                        }}
                      />
                    </Typography>
                  }
                />
                <div>
                  <LabelView articleLabelItems={initialArticleLabelItems} />
                </div>
                {!!article &&
                  article.hasOwnProperty("attachments") &&
                  article.attachments.hasOwnProperty("items") &&
                  article.attachments.items.length > 0 &&
                  article.attachments.items.map((item, i) => (
                    <React.Fragment key={item.id}>
                      {i > 0 && (
                        <div styleName="image-thumbnail">
                          <MediaThumbnail
                            image={item.path}
                            contentType={item.contentType}
                            styleName="image-thumbnail"
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ))}

                {!!article &&
                  article.hasOwnProperty("latitude") &&
                  article.hasOwnProperty("longitude") &&
                  !!article.latitude &&
                  !!article.longitude && (
                    <ViewLocation
                      latitude={article.latitude}
                      longitude={article.longitude}
                      positionName={article.positionName}
                    />
                  )}

                {article.id && (
                  <Button
                    variant="contained"
                    onClick={() => editArticle()}
                    id="edit_article_button"
                  >
                    <span aria-hidden="true">
                      <Translate id="edit" />
                    </span>
                  </Button>
                )}
              </React.Fragment>
            ) : (
              <div>
                <Translate id="article.not_extist" />
              </div>
            )}

            <Zoom in={checked} timeout={{ appear: 0, enter: 500, exit: 300 }}>
              <CheckCircleIcon
                color="secondary"
                styleName="splash-icon"
              ></CheckCircleIcon>
            </Zoom>
            <Zoom
              in={openDeleteSplash}
              timeout={{ appear: 0, enter: 500, exit: 300 }}
            >
              <DeleteIcon
                color="secondary"
                styleName="splash-icon"
              ></DeleteIcon>
            </Zoom>
            <Snackbar
              open={openSnackbar}
              onClose={handleCloseSnackbar}
              // autoHideDuration={6000}
            >
              <Alert
                onClose={handleCloseSnackbar}
                variant="filled"
                severity="error"
              >
                {messageSnackbar}
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <div className={classes.root} noValidate autoComplete="off">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={switchLocale()}
            >
              <DateTimePicker
                label={formatMessage({
                  id: "date",
                  defaultMessage: defaults["date"],
                })}
                id="postedDate"
                value={editFormState.postedDate}
                onChange={handlePostedDateChange}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              margin="dense"
              id="name"
              label={formatMessage({
                id: "title",
                defaultMessage: defaults["title"],
              })}
              type="text"
              onChange={(event) => setInputEdit("name", event.target.value)}
              value={editFormState.name}
              fullWidth
            />
            {switchState ? (
              <TextField
                margin="dense"
                id="description"
                label={formatMessage({
                  id: "description",
                  defaultMessage: defaults["description"],
                })}
                type="text"
                onChange={(event) =>
                  setInputEdit("description", event.target.value)
                }
                value={editFormState.description}
                fullWidth
                multiline
              />
            ) : (
              <CKEditor
                editor={ClassicEditor}
                config={
                  localStorage.getItem("lang") != "ar"
                    ? editorConfiguration
                    : editorConfigurationRtl
                }
                data={editFormState.description}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  // console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // console.log({ event, editor, data });
                  setInputEdit("description", data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            )}
            {/* <FormControlLabel
              className="switch_html"
              control={
                <Switch
                  checked={switchState}
                  onChange={changeSwitch}
                  name="html"
                />
              }
              label="HTML"
            /> */}
            <LabelEdit
              labels={labels}
              setLabels={setLabels}
              articleLabelItems={articleLabelItems}
              setArticleLabelItems={setArticleLabelItems}
              fetchArticle={fetchArticle}
              user={user}
            />
            <div>
              {!!article &&
                article.hasOwnProperty("attachments") &&
                article.attachments.hasOwnProperty("items") &&
                article.attachments.items.length > 0 &&
                article.attachments.items.map((item, i) => (
                  <div key={item.id} styleName="image-thumbnail">
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            console.log(
                              "refsDeleteAttachmentDialog",
                              refsDeleteAttachmentDialog
                            );
                            refsDeleteAttachmentDialog.current[
                              i
                            ].current.handleClickOpen();
                          }}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <MediaThumbnail
                        image={item.path}
                        contentType={item.contentType}
                      />
                    </Badge>
                    <AlertDialogSlide
                      ref={refsDeleteAttachmentDialog.current[i]}
                      message={formatMessage({
                        id: "article.delete_confirm",
                        defaultMessage: defaults["attachement.delete_confirm"],
                      })}
                      title_id="delete_attachment"
                      handleExecute={hundleDeleteAttachment}
                      input={item}
                    />
                  </div>
                ))}
            </div>
            <InputImageWithPreview fileRef={fileRef} />
            <InputLocation
              latitude={editFormState.latitude}
              longitude={editFormState.longitude}
              positionName={editFormState.positionName}
              setPositionSet={setPositionSet}
              setPositionName={setPositionName}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={hundleUpdateArticle}
              id="update_article_button"
            >
              <Translate id="update_article" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                refDeleteArticleDialog.current.handleClickOpen();
              }}
              id="delete_article_button"
            >
              <Translate id="delete_article" />
            </Button>
            <AlertDialogSlide
              ref={refDeleteArticleDialog}
              message={formatMessage({
                id: "article.delete_confirm",
                defaultMessage: defaults["article.delete_confirm"],
              })}
              title_id="delete_article"
              handleExecute={() => hundleDeleteArticle()}
              className="delete_article_dialog"
            />
            <Button variant="contained" onClick={() => setIsEditing(false)}>
              <Translate id="cancel" />
            </Button>
          </div>
        )}
      </div>

      <Backdrop styleName="backdrop" open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}
