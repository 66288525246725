import React, { useState, useEffect } from "react";
import clsx from "clsx";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import TimelineIcon from "@mui/icons-material/Timeline";
import EventIcon from "@mui/icons-material/Event";
import PhotoIcon from "@mui/icons-material/Photo";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import Avatar from "@mui/material/Avatar";
import Auth from "@aws-amplify/auth";
import { useHistory } from "react-router-dom";

import FilterListIcon from "@mui/icons-material/FilterList";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Chip from "@mui/material/Chip";
import { API, graphqlOperation } from "aws-amplify";
import { listLabels } from "../../graphql/queries";
import Translate from "../../components/part/Translate";

import { useAuthDataContext } from "../../containers/AuthDataProvider";

import { useIntl } from "react-intl";

import CSSModules from "react-css-modules";
import style from "./PersistentDrawerLeft.module.scss";

const defaults = require("../../translations/en.json");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default CSSModules(
  PersistentDrawerLeft,
  { ...style },
  { allowMultiple: true }
);

function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const {
    type,
    title,
    handleLabelFileter,
    filterLabelName,
    filterLabelColor,
    setIsLoading,
  } = props;

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handlePrevPage = () => {
    let prevPage = sessionStorage.getItem("prevPage");
    history.push(prevPage);
    setOpen(true);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [labels, setLabels] = useState([]);
  const getLabels = async () => {
    const res = await API.graphql(
      graphqlOperation(listLabels, {
        owner: user.username,
        // limit: 5,
        // nextToken: nextToken,
      })
    )
      .then((res) => {
        // console.log("res", res);
        setLabels(res.data.listLabels.items);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const user = useAuthDataContext();
  let user_id, user_name, user_picture;

  if (!!user) {
    // console.log("user", user);
    // console.log("user.attributes.sub", );
    user_id = user.attributes.sub;
    user_name = user.attributes.name;
    user_picture = user.attributes.picture;
  } else {
    user_id = "";
    user_name = "";
    user_picture = "";
  }

  const history = useHistory();

  const drawer = (
    <div className="dwawer">
      <div
        role="presentation"
        onClick={handleDrawerClose}
        onKeyDown={handleDrawerClose}
      >
        <IconButton onClick={handleDrawerClose} size="large">
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>

      <Divider />

      <List>
        {!!user_picture && (
          <ListItem>
            <ListItemIcon>
              {" "}
              <Avatar alt={user_name} src={user_picture} />{" "}
            </ListItemIcon>
            <ListItemText primary={user_name} />
          </ListItem>
        )}
        <ListItem button key="home" onClick={() => history.push("/timeline")}>
          <ListItemIcon>
            {" "}
            <TimelineIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.timeline",
              defaultMessage: defaults["menu.timeline"],
            })}
            className="menu_timeline"
          />
        </ListItem>
        <ListItem
          button
          key="calendar"
          onClick={() => history.push("/calendar")}
        >
          <ListItemIcon>
            {" "}
            <EventIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.calendar",
              defaultMessage: defaults["menu.calendar"],
            })}
            className="menu_calendar"
          />
        </ListItem>
        {/* <ListItem button key="searcn"
          onClick={() => history.push("/search")}
        >
          <ListItemIcon> <SearchIcon/> </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItem> */}
        <ListItem button key="media" onClick={() => history.push("/media")}>
          <ListItemIcon>
            {" "}
            <PhotoIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.media",
              defaultMessage: defaults["menu.media"],
            })}
            className="menu_media"
          />
        </ListItem>
        <ListItem button key="map" onClick={() => history.push("/map")}>
          <ListItemIcon>
            {" "}
            <MapIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.map",
              defaultMessage: defaults["menu.map"],
            })}
            className="menu_map"
          />
        </ListItem>
      </List>
      <Divider />

      <List>
        <ListItem
          button
          key="reload"
          onClick={() => {
            sessionStorage.clear();
            window.location.reload();
          }}
        >
          <ListItemIcon>
            {" "}
            <RotateLeftIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.reload",
              defaultMessage: defaults["menu.reload"],
            })}
            className="menu_reload"
          />
        </ListItem>
      </List>
      <Divider />

      <List>
        <ListItem
          button
          key="settings"
          onClick={() => history.push("/settings")}
        >
          <ListItemIcon>
            {" "}
            <SettingsIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.setting",
              defaultMessage: defaults["menu.setting"],
            })}
            className="menu_setting"
          />
        </ListItem>
        <ListItem
          button
          key="privacypolicy"
          onClick={() => history.push("/privacypolicy")}
        >
          <ListItemIcon>
            {" "}
            <SecurityIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.privacypolicy",
              defaultMessage: defaults["menu.privacypolicy"],
            })}
            className="menu.privacypolicy"
          />
        </ListItem>
      </List>

      <Divider />

      <List>
        {/* <ListItem button key="checkUser" onClick={checkUser} >
          <ListItemIcon> <PersonIcon/> </ListItemIcon>
          <ListItemText primary="CheckUser" />
        </ListItem> */}
        <ListItem button key="exit" onClick={signOut}>
          <ListItemIcon>
            {" "}
            <ExitToAppIcon />{" "}
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({
              id: "menu.exit",
              defaultMessage: defaults["menu.exit"],
            })}
          />
        </ListItem>
      </List>
    </div>
  );

  function signOut() {
    sessionStorage.clear();
    Auth.signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    getLabels();
  }, []);

  useEffect(() => {}, [filterLabelName]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {type === "timeline" ||
          type === "calendar" ||
          type === "photo" ||
          type === "map" ||
          type === "setting" ? (
            <React.Fragment>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
                size="large">
                <MenuIcon />
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
                size="large">
                {type === "timeline" && <TimelineIcon />}
                {type === "calendar" && <EventIcon />}
                {type === "photo" && <PhotoIcon />}
                {type === "map" && <MapIcon />}
                {type === "setting" && <SettingsIcon />}
              </IconButton>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <IconButton
                color="inherit"
                aria-label="open prevpage"
                onClick={handlePrevPage}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
                size="large">
                <ArrowBackIcon />
              </IconButton>
            </React.Fragment>
          )}
          <Typography variant="h5" noWrap className={classes.title}>
            {type === "article" ? title : user.attributes.email}
          </Typography>
          {(type === "timeline" ||
            type === "calendar" ||
            type === "photo" ||
            type === "map") &&
            !!labels &&
            labels.length > 0 && (
              <React.Fragment>
                <IconButton
                  aria-label="filter label"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  className="label_filter"
                  size="large">
                  {!!filterLabelName ? (
                    <Chip
                      styleName="label-chip"
                      label={"#" + filterLabelName}
                      size="small"
                      style={{
                        backgroundColor: filterLabelColor,
                        color: filterLabelColor,
                      }}
                    />
                  ) : (
                    <FilterListIcon />
                  )}
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openMenu}
                  onClose={handleClose}
                >
                  <MenuItem
                    data-label-name={""}
                    data-label-color={""}
                    onClick={(event) => {
                      handleLabelFileter(event);
                      handleClose();
                    }}
                  >
                    <Translate id="cancel" />
                  </MenuItem>
                  {labels.map((item, i) => (
                    <MenuItem
                      data-label-name={item.name}
                      data-label-color={item.color}
                      onClick={(event) => {
                        handleLabelFileter(event);
                        handleClose();
                      }}
                    >
                      {"#" + item.name}
                    </MenuItem>
                  ))}
                </Menu>
              </React.Fragment>
            )}
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {drawer}
      </SwipeableDrawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {props.children}
      </main>
    </div>
  );
}
