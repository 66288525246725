import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { MapContainer, TileLayer, Marker, LayersControl } from "react-leaflet";
import axios from "axios";

import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { renderToStaticMarkup } from "react-dom/server";
import { divIcon } from "leaflet";
import RoomIcon from "@mui/icons-material/Room";

import CSSModules from "react-css-modules";
import style from "./DisplayPosition.module.scss";

import Translate from "../../components/part/Translate";
import { useIntl } from "react-intl";
const defaults = require("../../translations/en.json");

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;
// const initialPosition = [51.505, -0.09]
// const initialPosition = [35.3628018715203, 138.7307789619081]
const initialPosition = [-77.08431022895206, 151.56463623046878];
const initialLat = -77.08431022895206;
const initialLng = 151.56463623046878;

const zoom = 18;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  locationInput: {
    width: "calc(92vw - 48px)",
  },
  coordinates: {
    marginLeft: "48px",
  },
}));

const IconPosition = forwardRef((props, ref) => {
  const classes = useStyles();

  const { latitude, longitude, positionName, setPositionSet, setPositionName } =
    props;
  const { formatMessage } = useIntl();

  const [errorMessage, setErrorMessage] = useState(null);

  /***** ユーザーの現在の位置情報を取得 *****/
  function successCallback(position) {
    console.log("緯度：" + position.coords.latitude);
    console.log("経度：" + position.coords.longitude);
    console.log("高度：" + position.coords.altitude);
    console.log("緯度・経度の誤差：" + position.coords.accuracy);
    console.log("高度の誤差：" + position.coords.altitudeAccuracy);
    console.log("方角：" + position.coords.heading);
    console.log("速度：" + position.coords.speed);

    let lat, lng;
    lat = position.coords.latitude;
    lng = position.coords.longitude;

    // let reverseGeoLocationURL = `https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=43.0686718333333&lon=141.351173694444`
    // let reverseGeoLocationURL = 'https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=' + refDraggableMarker.current.markerPosition.lat + '&lon=' + refDraggableMarker.current.markerPosition.lng
    // let reverseGeoLocationURL = 'https://nominatim.openstreetmap.org/reverse.php?lat=35.87695&lon=139.64798&zoom=18&format=jsonv2';
    let reverseGeoLocationURL =
      "https://nominatim.openstreetmap.org/reverse.php?lat=" +
      lat +
      "&lon=" +
      lng +
      "&zoom=18&format=jsonv2";

    console.log("reverseGeoLocationURL", reverseGeoLocationURL);

    axios
      // .get(`https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=43.0686718333333&lon=141.351173694444`)             //リクエストを飛ばすpath
      .get(reverseGeoLocationURL) //リクエストを飛ばすpath
      .then((response) => {
        // console.log("response", response);
        // console.log("response.data.results", response.data);
        setPositionSet(lat, lng, response.data.display_name);
      }) //成功した場合、positionSetを更新する（then）
      .catch((err) => {
        console.log("逆ジオロケーション通信に失敗しました", err);
        setErrorMessage("逆ジオロケーション通信に失敗しました");
      }); //失敗した場合(catch)
  }

  /***** 位置情報が取得できない場合 *****/
  function errorCallback(error) {
    var err_msg = "";
    switch (error.code) {
      case 1:
        err_msg = "位置情報の利用が許可されていません";
        break;
      case 2:
        err_msg = "デバイスの位置が判定できません";
        break;
      case 3:
        err_msg = "位置情報取得タイムアウトしました";
        break;
      default:
        err_msg = "予期せぬエラーが発生しました";
    }
    console.log("err_msg", err_msg); //デバッグ用
    console.log("error.message", error.message); //デバッグ用
    setErrorMessage(err_msg);
    setPositionSet(initialLat, initialLng, "");
  }

  useEffect(() => {
    if (!positionName) {
      let option = {
        enableHighAccuracy: true,
        timeout: 8000,
        maximumAge: 2000,
      };
      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        option
      );
    }
  }, []);

  return (
    <React.Fragment>
      <span>
        {!!positionName ? (
          <TextField
            // autoFocus
            margin="dense"
            id="locationname"
            label={formatMessage({
              id: "location",
              defaultMessage: defaults["location"],
            })}
            type="text"
            onChange={(event) => setPositionName(event.target.value)}
            value={positionName}
            // fullWidth
            className={classes.locationInput}
          />
        ) : !!errorMessage ? (
          errorMessage
        ) : (
          <Translate id="requesting_location" />
        )}
      </span>
      <span className={classes.coordinates}>
        ({!!latitude && latitude},{!!longitude && longitude})
      </span>
    </React.Fragment>
  );
});

const DisplayPosition = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    getMarkerPosition,
  }));

  const { map, refDraggableMarker } = props;
  const { latitude, longitude, positionName, setPositionSet, setPositionName } =
    props;

  const [apiResult, setApiResult] = useState();

  const jumpCurrentPosition = useCallback(() => {
    console.log("start jumpCurrentPosition");
    map.locate();
  }, [map]);

  const getMarkerPosition = useCallback(() => {
    console.log("start getMarkerPosition");

    if (refDraggableMarker.current) {
      const marker = refDraggableMarker.current.markerRef.current;

      let lat, lng;

      if (marker != null) {
        console.log("marker.getLatLng()", marker.getLatLng());
        // refDraggableMarker.current.setMarkerPosition(marker.getLatLng());

        lng = marker.getLatLng().lng % 360;
        lat = marker.getLatLng().lat;
        // lng = refDraggableMarker.current.markerPosition.lng % 360;
        if (lng > 180) {
          lng = lng - 360;
        } else if (lng < -180) {
          lng = lng * 360;
        }
        // lat = refDraggableMarker.current.markerPosition.lat;
      }

      // let reverseGeoLocationURL = `https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=43.0686718333333&lon=141.351173694444`
      // let reverseGeoLocationURL = 'https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=' + refDraggableMarker.current.markerPosition.lat + '&lon=' + refDraggableMarker.current.markerPosition.lng
      // let reverseGeoLocationURL = 'https://nominatim.openstreetmap.org/reverse.php?lat=35.87695&lon=139.64798&zoom=18&format=jsonv2';
      let reverseGeoLocationURL =
        "https://nominatim.openstreetmap.org/reverse.php?lat=" +
        lat +
        "&lon=" +
        lng +
        "&zoom=18&format=jsonv2";

      console.log("reverseGeoLocationURL", reverseGeoLocationURL);

      axios
        // .get(`https://mreversegeocoder.gsi.go.jp/reverse-geocoder/LonLatToAddress?lat=43.0686718333333&lon=141.351173694444`)             //リクエストを飛ばすpath
        .get(reverseGeoLocationURL) //リクエストを飛ばすpath
        .then((response) => {
          // console.log("response", response);
          // console.log("response.data.results", response.data);
          setApiResult(response.data.display_name);
          setPositionSet(lat, lng, response.data.display_name);
        }) //成功した場合、positionSetを更新する（then）
        .catch((err) => {
          console.log("逆ジオロケーション通信に失敗しました", err);
        }); //失敗した場合(catch)
    }
  }, []);

  const onMove = useCallback(
    (e) => {
      let c = map.getCenter();
      console.log("map onMove", e);
      console.log("map.getCenter()", c);
      //マップの中央にマーカーを固定
      refDraggableMarker.current.setMarkerPosition({ lng: c.lng, lat: c.lat });
    },
    [map]
  );

  const onMouseup = useCallback(
    (e) => {
      let c = map.getCenter();
      console.log("map onMouseup", e);
      console.log("map.getCenter()", c);

      //マーカー位置情報取得
      getMarkerPosition();
    },
    [map]
  );

  const onLocationFound = useCallback(
    (e) => {
      console.log("start onLocationFound");
      console.log("e.latlng", e.latlng);

      refDraggableMarker.current.setMarkerPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());

      getMarkerPosition();
      // const radius = e.accuracy;
      // const circle = L.circle(e.latlng, radius);
      // circle.addTo(map);
      // setBbox(e.bounds);
    },
    [map]
  );

  const onLocationError = useCallback(
    (e) => {
      //map.locate()で現在地移動に失敗した時
      console.log("位置情報取得に失敗しました。");
      window.alert("位置情報取得に失敗しました。");
      setPositionSet(initialLat, initialLng, "");
    },
    [map]
  );

  const onFlyend = useCallback(
    (e) => {
      // refDraggableMarker.current.setMarkerPosition([latitude,longitude]);
    },
    [map]
  );

  useEffect(() => {
    if (!positionName) {
      jumpCurrentPosition();
    } else {
      console.log(
        "'current' in refDraggableMarker",
        "current" in refDraggableMarker
      );
      console.log(
        "typeof(refDraggableMarker.current)",
        typeof refDraggableMarker.current
      );
      console.log("refDraggableMarker.current", refDraggableMarker.current);
      // console.log("'setMarkerPosition' in refDraggableMarker.current", 'setMarkerPosition' in refDraggableMarker.current);
      if (!!refDraggableMarker.current) {
        refDraggableMarker.current.setMarkerPosition([latitude, longitude]);
        map.flyTo([latitude, longitude], map.getZoom());
      }
    }
  }, []);

  useEffect(() => {
    map.on("load", getMarkerPosition());
    map.on("move", onMove);
    map.on("mouseup", onMouseup);

    map.on("locationfound", onLocationFound);
    map.on("locationerror", onLocationError);
    map.on("moveend", ({ originalEvent }) => {
      if (originalEvent) {
        map.fire("usermoveend");
      } else {
        map.fire("flyend");
      }
    });
    map.on("flyend", onFlyend);

    console.log("refDraggableMarker", refDraggableMarker);

    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove, apiResult]);

  return (
    <React.Fragment>
      <button onClick={jumpCurrentPosition} styleName="get-current-button">
        <Translate id="get_current_position" />
      </button>
    </React.Fragment>
  );
});

const DraggableMarker = forwardRef((props, ref) => {
  const { refDisplayPosition, latitude, longitude } = props;

  useImperativeHandle(ref, () => ({
    markerPosition,
    setMarkerPosition,
    markerRef,
  }));

  const [markerPosition, setMarkerPosition] = useState([latitude, longitude]);
  const markerRef = useRef(null);

  const iconMarkup = renderToStaticMarkup(
    // <RoomIcon fontSize='large' color='secondary'/>
    <RoomIcon style={{ fontSize: 50 }} color="secondary" />
  );
  const customMarkerIcon = divIcon({
    html: iconMarkup,
    className: "hogehoge", // CSSのクラス名。指定しないとデフォルトのleaflet-div-iconというクラスがつき、background: #fff;border: 1px solid #666;というスタイルがつきます
    iconSize: [50, 90], // iconSizeを指定しないと、マーカーの中心でなく、左上が指定した座標に来るようになります
  });

  return markerPosition === null ? null : (
    <Marker
      icon={customMarkerIcon}
      // draggable={true}
      // eventHandlers={{
      //   click: (e) => {
      //     console.log('marker clicked', e)
      //     refDisplayPosition.current.getMarkerPosition()
      //   },
      //   dragstart: (e) => {
      //     console.log('marker dragstart', e)
      //     refDisplayPosition.current.getMarkerPosition()

      //   },
      //   drag: (e) => {
      //     console.log('marker drag', e)
      //     refDisplayPosition.current.getMarkerPosition()

      //   },
      //   dragend: (e) => {
      //     console.log('marker dragend', e)
      //     refDisplayPosition.current.getMarkerPosition()

      //   },
      // }}
      position={markerPosition}
      ref={markerRef}
    ></Marker>
  );
});

function InputLocation(props) {
  const classes = useStyles();
  const { latitude, longitude, positionName, setPositionSet, setPositionName } =
    props;

  const refDisplayPosition = useRef();
  const refDraggableMarker = useRef();

  const [map, setMap] = useState(null);

  const [editLatitude, setEditLatitude] = useState(latitude);
  const [editLongitude, setEditLongitude] = useState(longitude);
  const [editPositionName, setEditPositionName] = useState(positionName);
  function setEditPositionSet(lat, lng, posName) {
    setEditLatitude(lat);
    setEditLongitude(lng);
    setEditPositionName(posName);
  }

  React.useEffect(() => {
    if (!!refDisplayPosition.current) {
    }
  }, [refDisplayPosition.current]);

  const displayMap = (
    <React.Fragment>
      {!!latitude && !!longitude ? (
        <MapContainer
          center={[latitude, longitude]}
          zoom={zoom}
          scrollWheelZoom={false}
          whenCreated={setMap}
          // style={{ height: "calc(100vh - 64px)" }}
        >
          <LayersControl position="bottomleft">
            <LayersControl.BaseLayer checked name="地図">
              <TileLayer
                attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
                url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="地図＋写真">
              <TileLayer
                attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
                url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="写真">
              <TileLayer
                attribution="<a href='https://developers.google.com/maps/documentation' target='_blank'>Google Map</a>"
                url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              />
            </LayersControl.BaseLayer>
            <DraggableMarker
              ref={refDraggableMarker}
              map={map}
              refDisplayPosition={refDisplayPosition}
              latitude={latitude}
              longitude={longitude}
            />
          </LayersControl>
        </MapContainer>
      ) : null}
    </React.Fragment>
  );

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEditPositionSet(latitude, longitude, positionName);
    setOpen(false);
  };

  const handleSubmit = () => {
    setPositionSet(editLatitude, editLongitude, editPositionName);
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        styleName="edit-icon"
        aria-label="edit"
        onClick={handleClickOpen}
        size="large">
        <LocationOnIcon />
      </IconButton>
      <IconPosition
        latitude={latitude}
        longitude={longitude}
        positionName={positionName}
        setPositionSet={setPositionSet}
        setPositionName={setPositionName}
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <Translate id="select_position" />
            </Typography>
            <IconButton
              autoFocus
              color="inherit"
              onClick={() => {
                console.log("決定");
                handleSubmit();
              }}
              size="large">
              <DoneIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {map ? (
          <React.Fragment>
            <div>
              <IconButton
                styleName="edit-icon"
                // onClick={handleClickOpen}
                aria-label="edit"
                size="large">
                <LocationOnIcon />
              </IconButton>
              <IconPosition
                latitude={editLatitude}
                longitude={editLongitude}
                positionName={editPositionName}
                setPositionSet={setEditPositionSet}
                setPositionName={setEditPositionName}
              />
            </div>
          </React.Fragment>
        ) : null}
        {displayMap}
        {map ? (
          <React.Fragment>
            <DisplayPosition
              ref={refDisplayPosition}
              map={map}
              refDraggableMarker={refDraggableMarker}
              latitude={editLatitude}
              longitude={editLongitude}
              positionName={editPositionName}
              setPositionSet={setEditPositionSet}
              setPositionName={setEditPositionName}
            />
          </React.Fragment>
        ) : null}
      </Dialog>
    </div>
  );
}
export default DisplayPosition;
// export default CSSModules(
//   DisplayPosition,
//   { ...style },
//   { allowMultiple: true }
// );
