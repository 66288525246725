import React from "react";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  codeblock: {
    position: "fixed",
    width: "100%",
    textAlign: "center",
    bottom: "45%",
  },
  code: {
    fontSize: "4em",
  },
  description: {
    fontSize: "2em",
  },
}));

export default function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.codeblock}>
      <div className={classes.code}>404</div>
      <div className={classes.description}>FOT NOUND</div>
    </div>
  );
}
